.navbar-light .navbar-nav .nav-link {font-style: normal;font-weight: 400;font-size: 20px;color: #252a36;line-height: 35px;text-transform: capitalize;font-family: 'Sora', sans-serif !important;border-bottom: 2px solid transparent; margin-right: 20px;}
.navbar-light .navbar-nav .nav-link:hover { font-family: 'Sora', sans-serif !important;border-bottom: 2px solid #4ddebc;}
.navbar-light .navbar-nav .nav-link:last-child {margin-right: 0px;}
.navbar-expand-lg .navbar-nav {width: 100%;}
.navbar-toggler:focus {text-decoration: none;outline: 0;box-shadow: 0 0 0 !important;}
.main-header {
  /* position: fixed;
  width: 100%;
  z-index: 999;
  top: 0; */
background: #eaedff;
}
.navbar.navbar-light.bg-light {
  background: #eaedff !important;
  min-height: 76px;
}
.navbar-toggler.collapsed {background: transparent; border: 1px solid #fff !important;}
.navbar-toggler .navbar-toggler-icon{ background: url(../Assest/Images/bar.png) !important; background-size: 100% !important;
  background-repeat: no-repeat !important;
  background-position: center !important;}
  .navbar-toggler.collapsed {border: 1px solid transparent !important;}
  .navbar-collapse {
    /* background:#eaedff; */
    /* padding: 25px;  */
    z-index: 999;}
.s-icons
{
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  margin-left: 20px;
}
.s-icons span {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  
}
.s-icons img {
    width: 36px;
}

/* .navbar-expand-lg .navbar-nav .dropdown-menu {
  background: rgba(255, 244, 242, 0.3) !important;
}
.class2.navbar.navbar-expand-lg.navbar-light.fixed-top {
  background: rgba(255, 255, 255, .4);
}
.navbar.navbar-expand-lg.navbar-light.fixed-top.class1 {
  background: rgba(255, 244, 242, .9) !important;
} */

@media (min-width: 992px) and (max-width:1190px) {
  .navbar-light .navbar-nav .nav-link {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #252a36;
    line-height: 22px;
    text-transform: capitalize;
    font-family: 'Sora', sans-serif !important;
    border-bottom: 2px solid transparent;
    margin-right: 12px;
}
  
}
@media (min-width: 992px) {
.navbar-brand img {
  width: 197px;
  /* margin-top: -26px; */
}

}

.footer{background:#162d61;}  
.career p, .career a {font-weight: 300;font-size: 18px;color: #a4aec5;text-decoration: none;margin-bottom: 0;}
.career { padding-top: 35px; display: flex; align-items: flex-start; gap: 20px;}
.career>img
{
  width: 50px;margin-top: 8px;
}
.foot_menu h3 {font-size: 25px;text-transform: uppercase;margin-bottom: 26px;}
.foot_social_menu h3 {
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 26px;
}
.social_icons_w
{
  margin-top: 10px;
}
.social_icons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 4px;
  margin-bottom: 24px;
}
.social_icons a img { 
  width: 40px;
  height: 40px; opacity: 0.7;
  transition: opacity 0.4s;
}
.social_icons a:hover img { 
  width: 40px;
  height: 40px; opacity: 1;
}
.foot_menu .nav-link {font-size: 17px;line-height: 30px;padding-bottom: 12px;font-weight: 300;color: #a4aec5;}
.foot_social_menu .nav-link{font-size: 17px;line-height: 20px;padding-bottom: 6px;font-weight: 300;color: #a4aec5;width: auto; display: inline-block;}
.foot_social_menu .u-details{border-bottom: 1px solid;padding-bottom: 10px; display: flex;flex-flow: column; gap: 10px;}
.footer p.foot_text {font-size: 17px;font-weight: 300;color: #a4aec5;}
.copyrt p {color: #fff;font-size: 14px;text-align: center;border-top: 1px solid #fff;padding-top: 45px;margin-top: 30px;}




@media (max-width:992px) {
  .s-icons
  {
    display: flex !important;
    flex-wrap: nowrap;
    margin-left: 0;
  }
  .navbar-light .navbar-nav .nav-link
  {
    line-height: 30px !important;
    text-align: center;
  }
  .navbar-brand
  {
    max-width: 220px;
  }
}


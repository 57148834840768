.innerpage_banner {
    position: relative;
    display: flex;
    z-index: 2;
    margin-top: 42px;
    justify-content: flex-end;
    align-items: center;
}

.innerpagebanner_img {
    text-align: end;
    padding-top: 52px;
    overflow: hidden;
}

.orange-banner-bg  {
    height: 780px;
}

.app-store-icons {
    display: flex;
    gap: 10px;
    padding-left:10px;
    padding-right: 10px;
    flex-shrink: 0;
    justify-content: center;
    position: absolute;
    bottom: 60px;
    right: 20px;
    left: auto;
}
.bot-0
{
    bottom: 0px;
}
.right-0
{
    right: 0 !important;
}
.left-0
{
    left: 0 !important;
}
.mt-6
{
    margin-top: 6rem !important;
}
.app-relative-icons
{
    position: relative;
    bottom:auto;
    margin-top: 20px;
}
.app-store-icons a img {
    width: 150px;
    max-width: 100% !important;
}
.metafit-icon
{
    left: auto;
    right: 30px;
}
.all-take-w {
    width: calc(100% - 421px);
    text-align: center;
}

.innerpagebanner_img img {
    object-fit: cover;
    object-position: right;

}

.orange-banner-bg {

    display: grid;
    padding-top: 120px;
    align-items: center;
    padding-bottom: 120px;
    text-align: center;
    color: #fff;
    min-height: 812px;
    width: 421px;
    max-width: 100%;

}

.orange-banner-bg img {
    margin-bottom: 74px;
}

.orange-banner-bg h2 {
    font-size: 49px;
    padding: 10px;

}

.orange-banner-bg p {
    font-size: 32px;
    color: #fff;
}

.what-is-section {


    padding: 0;
    overflow: hidden;
    margin-top: -150px;
    padding-top: 205px;
    padding-bottom: 100px;
    position: relative;
    z-index: 1;

}

.subpage-heading {
    font-size: 72px;
    font-weight: 500;
    letter-spacing: -0.9px;
    color: #1b2939;
    text-transform: capitalize;
}

.para-grey {
    color: #666666;
    font-size: 30px;
    line-height: 50px;
    font-weight: 300;
    padding-right: 25px;
    padding-top: 36px;
}

/* project-idea */
.project-idea {
    padding: 20px 0;
    overflow: hidden;
}

.heading-box-end {
    transition: transform .3s;
    z-index: 3;
    padding-left: 0;
    padding-top: 60px;
    letter-spacing: -0.9px;
    width: auto;
    display: inline-block;
    /* background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 120px), #ff8000 120px); */
    text-transform: capitalize;
    position: relative;
}

.max-height-90 {
    max-height: 90%;
}

.max-height-80 {
    max-height: 90%;
}

.max-height-70 {
    max-height: 500px;
}

.blue-text {
    color: #0d1462 !important;
}

.heading-box-end::after {
    content: ' ';
    display: block;
    width: 70px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    right: 0;
    background: #ff8000;
}

.heading-box {
    background: #ddf4fc;
    padding: 25px;
    margin-bottom: 90px;
}

.m-minus-3 {
    position: relative;
    top: -300px;
    right: 0;
}

.appWauto {
    width: fit-content;
    height: fit-content;
    position: relative;
}

.appWauto::after {
    content: '';
    display: block;
    position: absolute;
    top: -102px;

    z-index: -1;
    width: 200%;
    height: 173px;
    background: #e0dced;
    border: 2px solid #fceadc;
    margin-top: 38px;
}

.appWauto.left-pos::after {
    /* left: 103px; */
    left: 226px;
}

.appWauto.right-pos::after {
    right: 103px;
}

.webAppDisplay {
    padding: 12px 0;
    overflow: hidden;
    margin-top: -180px !important;
}

.project-pad {
    margin-top: 70px !important;
}

.mt-120 {
    margin-top: 120px !important;
}

.admin-app {
    padding-top: 150px;
}

.core-features {
    padding: 0 0;
    margin: 18px 0;
    overflow: hidden;

}
.updx{
    max-width: 57% !important;
}
/* #Services {
    padding: 70px 0;
} */

.list-circle-arrow2>li {
    background: url(../Assest/Images/circle-arrowb.png) no-repeat;
    background-position: left 14px;
    padding-left: 60px;
    list-style-type: none;
    font-size: 38px;
    font-weight: 300;
    text-indent: 0px;
    line-height: 62px;
    letter-spacing: -0.1px;
    color: #666666;
}

.core-features-inner {

    padding: 90px 20px;
    position: relative;
    display: grid;
}

.core-features-inner1 {

    padding: 90px 0px;
    position: relative;
    display: grid;
}

.core-features-inner::before {
    display: block;
    content: '';
    position: absolute;
    width: 121px;
    right: 100%;
    height: 100%;
    background: #fff;
}

.core-features-inner::after {
    display: block;
    content: '';
    position: absolute;
    width: 121px;
    left: 100%;
    height: 100%;
    background: #fff;
}

.heading-inside-bg {
    padding-left: 0;
    color: #fff;
    color: #fff;
    letter-spacing: -0.9px;
    position: relative;
}

.heading-outside-bg::after {

    content: '';
    display: block;
    width: 1500px;

    transition: transform .3s;
    position: relative;
    height: 110px;
    top: -90px;
    left: 0;
    z-index: -1;
    transform: translateX(-30%);
}

.heading-outside-bg-black::after {
    content: '';
    display: block;
    width: 1464px;
    transition: transform .3s;
    position: relative;
    height: 210px;
    top: -210px;

}

.heading-outside-bg2::after {
    width: 1700px;
}

.proj-block-small,
.proj-block-big,
.proj-block-big-g {
    position: relative;

}

.proj-block-small::before {
    position: absolute;
    display: block;
    content: '';
    width: 121px;
    height: 131px;
    z-index: -1;
    top: 26%;
    left: -25px;
}

.proj-block-big::before {
    position: absolute;
    display: block;
    content: '';
    width: 424px;
    max-width: 65%;
    max-height: 40%;
    height: 420px;
    z-index: -1;
    top: 10%;
    left: 0;
}

.proj-block-big-g::before {
    position: absolute;
    display: block;
    content: '';
    width: 424px;
    max-width: 45%;
    max-height: 60%;
    height: 420px;
    z-index: -1;
    top: 0%;
    left: 0;
}

.proj-block-small.orange::before {
    background: #ff8000;
}

.proj-block-small.maroon::before {
    background: #bf0a13;
}

.proj-block-small.mpurplelt::before {
    background: #867ef0;
}

.proj-block-small.purple::before {
    background: #6526f9;
}

.proj-block-small.crocus::before {
    background: #6f62cd;
}

.proj-block-small.yellowlt::before {
    background: #fff0d3;
}

.proj-block-big-g.agreen::before {
    background: #8afeb5;
}

.proj-block-big.red::before {
    background: #e83c30;
}

.f-38 {
    font-size: 38px;
    color: #666666;
}

.display-app {
    padding: 70px 0;
    overflow: hidden;
}

.display-app .project-pad {
    padding-top: 145px;
}

/* .l-h-e li
{
    padding-top: 15px;
    padding-bottom: 15px;
    
} */
.m-t-m350 {
    margin-top: -350px;
    padding-top: 350px;
    padding-bottom: 100px;
}

.margin-top-10 {
    margin-top: -1rem;
}

.margin-top-20 {
    margin-top: -2rem;
}

.margin-top-50 {
    margin-top: -5rem;
}

.inner_subappservice {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    align-items: flex-start;
    position: relative;
    padding-top: 55px;
}

.inner_subappservice1 {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    position: relative;
    padding-top: 55px;
}

.inner_subappservice1 .appservice_grd {
    min-width: 339px;
    height: fit-content;
}

.inner_subappservice1 .appservice_grd:nth-child(2),
.inner_subappservice1 .appservice_grd:nth-child(5) {
    margin-top: 0 !important;
}

.appservice_grd {
    font-size: 28px;
    line-height: 55px;
    min-height: 217px;
    display: grid;
    justify-content: center;
    align-items: center;
    color: #1b2939;
    border-radius: 10px;
    padding: 30px 20px;
}

.appservice_grd .grd_txt {
    display: grid;
    justify-content: center;
    align-items: center;
}

.appservice_grd:nth-child(2),
.appservice_grd:nth-child(5) {
    margin-top: 60px;
}

.appservice_grd:nth-child(2),
.appservice_grd:nth-child(4),
.appservice_grd:nth-child(6) {

    color: #fff;
}

.col-app {
    gap: 60px;
    display: grid;
}

.col-app .appservice_grd {
    margin-top: 0 !important;
}

.appservice_grd p {
    font-size: 28px;
}

.tech-stack-block {
    padding: 100px 0;
    margin-top: 80px;
    overflow: hidden;

}

.tech-stack-block .slick-next {
    right: 0;
}

.tech-stack-block .slick-prev {
    left: 0;
}

.tech-stack-block .innerstack_sl {
    box-shadow: none;
    padding: 0 20px;
    background: #fff;
}

.tech-stack-block .stack_sl {
    margin: 0 15px;
    background: #fff;

}

.tech-stack-block .slick-slider.stack_slider {
    margin-top: 0;
}

.techstack-app {
    padding: 50px;
    background: #fff;
}

.tech-stack-icons {
    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: space-evenly !important;
    margin-top: 60px;
    flex-wrap: wrap;
    max-width: 1100px;
    margin: 60px auto;
}

.tech-stack-icons img {
    max-width: 100%;
}

.hr-border-label {
    width: 100%;
    height: 0;
    border: 2px solid;
    position: relative;
    margin-top: 45px;
    margin-bottom: 45px;
}

.hr-border-label2 {
    width: 100%;
    height: 0;
    border: 2px solid;
    position: absolute;
    top: 50%;
}

.rating-app {
    min-height: 530px;
    padding-top: 265px;
}

.rating-app2 {
    height: 706px;
    position: relative;
    min-height: 706px;
    margin-top: 6rem;
}

.rating-1 {
    position: absolute;
    top: calc(100% / 2 - 35px);
    left: 25%;
}

.rating-1-content-yg {
    top: 105px !important;
}

.rating-1-content {
    position: absolute;
    left: calc(25% - 92px);
    width: -moz-fit-content;
    width: 250px;
    display: grid;
    justify-content: center;
    top: -255px;
    text-align: center;
    gap: 150px;
}

.rating-2-content-yg {
    top: 205px !important;
}

.rating-2-content {
    position: absolute;
    right: calc(25% - 92px);
    width: -moz-fit-content;
    width: 250px;
    display: grid;
    justify-content: center;
    top: -135px;
    text-align: center;
    gap: 150px;
}

.circle-rate {
    width: 168px;
    height: 168px;
    background: #fff;
    border: 4px solid;
    border-radius: 50%;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    color: #666666;
    font-size: 65px;
    font-weight: 300;

    position: relative;
}

.circle-rate-2 {
    width: 168px;
    height: 168px;

    border: 4px solid #fff;
    border-radius: 50%;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 65px;
    font-weight: 300;
    position: relative;
}

.label-text {
    color: #1b2939;
    font-size: 48px;
}

.rating-2 {
    position: absolute;
    top: calc(100% / 2 - 35px);
    right: 25%;
}

.delivery-text {
    padding: 0 50px;

}

.delivery-text h3 {

    font-size: 49px;
    line-height: 82px;
    color: #fff;
}

.delivery-text h4 {

    font-size: 44px;

    color: #fff;
}

.app-d {
    margin-bottom: 12rem;
}

.contact-us-b {
    color: #2d2523 !important;
    font-size: 36px !important;
    background: #fff !important;
    text-transform: uppercase;
    padding-left: 60px !important;
    padding-right: 60px !important;
}

.view-more-b {
    color: #fff !important;
    font-size: 36px !important;
    background: #867ef0 !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 70px !important;
    padding-right: 70px !important;
}

.bg-bbdark {
    background: #0d1462 !important;
}

.rating-wrap {
    position: relative;
    display: grid;
    height: 100%;
}

.labels-rating2 {
    position: relative;
    display: grid;
    height: 100%;
}

.bottom-img-app img {
    position: relative;
    bottom: -105px;
}



/* themes */
.center-img {
    object-position: center center !important;
    margin: 0 auto !important;
}

.w-lg-75 {
    width: 75% !important;
}

.w-lg-90 {
    width: 90% !important;
}

.border-color-orange {
    border-color: #ff8000 !important;
}

.border-color-ygreen {
    border-color: #49b092 !important;
}

.border-color-maroon {
    border-color: #bf0a13 !important;
}

.border-color-red {
    border-color: #e83c30 !important;
}

.border-color-agreen {
    border-color: #8afeb5 !important;
}

.bg-lt-sky {
    background: #dfefff !important;
}

.bg-orange {
    background: #ff8000 !important;
}

.bg-orange-light {
    background: #ffe9d2 !important;
}

.head-box-orange.heading-box-end::after {
    background: #ff8000;

}

.head-box-orange {
    color: #1b2939;
}

/* .head-box-orange
{
    background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 120px), #ff8000 120px);
    color: #1b2939;
} */
.bg-paleyellow {
    background: #fff2d5;
}

.bg-ltred {
    background: #ffccdf;
}

.bg-altviolet {
    background: #f6eafe;
}

.bg-a-ltgreen {
    background: #cbeee8;
}

.bg-ltpnkred {
    background: #e0dced !important;
}

.bg-a-ltblue {
    background: #d5e6fa !important;
}

.bg-abgrey {
    background: #e8f1ff !important;
}

.bg-ltyellow {
    background: #fceadc !important;
}

.lt-paleyellow {
    background: #fdf7eb !important;
}

.bg-ltredorange {
    background: #fbdddd !important;
}

.bg-sgreenlt {
    background: #ddf4fc !important;
}

.bg-agreen {
    background: #8afeb5 !important;
}

.bg-agreen-light {
    background: #8afeb5 !important;
}

.head-box-agreen.heading-box-end::after {
    background: #8afeb5;
    /* color: #000000; */
}

.head-box-agreen {
    /* background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 120px), #8afeb5 120px); */
    color: #000000;
}

.bg-black {
    background: #000 !important;
    color: #fff !important;
}

.bg-augusta-light {
    background: #efedff;
}

.bg-crocus {
    background: #6f62cd;
}

.bg-ygreen-lt {
    background: #adfde6;
}

.bg-ygreen {
    background: #49b092;
}

.bg-creame-grey {
    background: #ececec;
}

.bg-blue {
    background: #050371;
}

.bg-blue-lt {
    background: #e2e1ff;
}

.bg-red-lt {
    background: #ffc7ca;
}

.bg-red-plt {
    background: #ffebfd;
}

.bg-maroon {
    background: #bf0a13;
}

.head-box-augusta.heading-box-end::after {
    /* background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 70px), #efedff 70px); */
    background: #efedff !important;
}

.head-box-sgreenlt.heading-box-end::after {
    background: #ddf4fc !important;
}

.head-box-sgreenlt {
    /* background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 70px), #ddf4fc 70px);     */
    color: #0d1462;
}

.heading-box-end.head-box-redorangelt::after {
    background: #fbdddd !important;
    /* color:#0d1462 ; */
}

.head-box-redorangelt {
    /* background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 65px), #fbdddd 65px);     */
    color: #0d1462;
}


.head-box-ygreen.heading-box-end::after {
    background: #adfde6;

}

.head-box-augustaW.heading-box-end::after {
    background: #fff;

}

.head-box-red.heading-box-end::after {
    background: #e83c30;
}

.head-box-red {
    /* background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 120px), #e83c30 120px); */
    color: #2d2523;
}

.head-box-purple.heading-box-end::after {
    background: #eeefff;

}

.head-box-purple {
    /* background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 120px), #eeefff 120px); */
    color: #2b2245;
}

.head-box-redlt.heading-box-end::after {
    /* background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 120px), #ffc7ca 120px); */
    background: #ffc7ca !important;

}

.head-box-redlt {
    color: #050371;
}

.head-box-redplt.heading-box-end::after {
    background: #e1d5ff;

}

.head-box-redplt {
    /* background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 120px), #e1d5ff 120px); */
    color: #050371;
}

.head-box-white.heading-box-end::after {
    background: #fff;
}

.head-box-white {
    /* background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 120px), #fff 120px); */
    color: #2b2245;
}

.text-default {
    color: #1b2939 !important;
}

.text-ablue {
    color: #0d1462 !important;
}

.text-brown {
    color: #2d2523 !important;
}

.text-blue {
    color: #050371 !important;
}

.text-purple {
    color: #2b2245 !important;
}

.text-black {
    color: #000 !important;
}

.bg-purple {
    background: #2b2245;

}

.bg-purple-light {
    background: #eeefff;
    color: #2b2245 !important;
}

.bg-grey-light {
    background: #f0f0f0;
    color: #666666 !important;
}

.text-white {
    color: #fff !important;
}

.bg-violet {
    background: #6526f9;
    color: #fff;
}

.bg-grey {
    background: #e6e6e6;
}

.bg-red {
    background: #e83c30;
}

.bg-grdpurplepink {
    background: rgb(128, 128, 243) !important;
    background: linear-gradient(38deg, rgba(128, 128, 243, 1) 0%, rgba(206, 82, 192, 1) 100%) !important;
}

.bg-grdpurplepink-lt {
    background: #eeefff;
}

.bg-red-light {
    background: #fecfcb !important;
}

.outside-orange::after {
    background: #ff8000 !important;
}

.outside-purplpinkgrd::after {
    background: rgb(128, 128, 243) !important;
    background: linear-gradient(38deg, rgba(128, 128, 243, 1) 0%, rgba(206, 82, 192, 1) 100%) !important;
    width: 1400px;
}

.outside-ygreen::after {
    background: #49b092 !important;
}

.outside-black::after {
    background: #000 !important;
}

.outside-purple::after {
    background: #6526f9 !important;
}

.outside-red::after {
    background: #e83c30 !important;
}

.bg-o-grd {
    background: rgb(255, 233, 210);
    background: linear-gradient(90deg, rgba(255, 233, 210, 1) 0%, rgba(255, 233, 210, 1) 5%, rgba(255, 255, 255, 1) 5%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 95%, rgba(255, 233, 210, 1) 95%, rgba(255, 233, 210, 1) 95%);
}

.circle-list {
    margin-left: 0;
    padding-left: 0;
}

.circle-list>li {
    padding-left: 60px;
    background-image: url(../Assest/Images/circle-bullet.png);
    background-size: auto;
    background-position: left 15px;
    background-repeat: no-repeat;
    list-style-type: none;

    padding-bottom: 10px;
}

.blue-circle-list>li {
    background-image: url(../Assest/Images/bluebullet.png) !important;
    background-size: auto;
    background-position: left 15px;
    background-repeat: no-repeat;
    list-style-type: none;

    padding-bottom: 10px;
}

.circle-list span {
    color: #666666;
}

.circle-list>ul {
    margin-top: -5px;
    margin-bottom: 5px;
}

.circle-list>ul>li {
    color: #666666;
    background-image: url(../Assest/Images/bullet-grey.png);
    background-size: auto;
    background-position: left 11px;
    background-repeat: no-repeat;
    list-style-type: none;
    margin-left: 30px;
    padding-left: 35px;
}

.core-feature-left {
    padding-left: 120px;
}

.zigzag {
    display: flex;
    gap: 5rem;
    align-items: center;
    position: relative;

}

.t-i-0 {
    text-indent: 0 !important;
}

.js-bw {
    justify-content: space-between !important;
}

.img-fluid {


    max-width: 100% !important;
}

.mx-w-100 {
    max-width: 100% !important;
}

.d-zgrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.zig-grid {
    display: grid;
    grid-template-columns: 1fr 40%;
    gap: 20px;
}

.zig-grid2 {
    display: grid;
    grid-template-columns: 33% 1fr;
    gap: 20px;
}

.img-max-70 {
    max-width: 70% !important;
    height: auto;
}

.img-max-50 {
    max-width: 70% !important;
    height: auto;
}

.img-max-md50 {
    max-width: 100% !important;
    height: auto;
}

.img-max-25 {
    max-width: 25% !important;
    height: auto;
}

.container {
    max-width: 1180px !important;

}

.sentensecase {
    text-transform: none !important;
}

.fixed-icons {
    position: fixed;
    z-index: 9999;
    bottom: 1rem;
    left: 1rem;
    display: flex;
    flex-flow: column;
}

.s-icons span {

    border-radius: 30px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.s-icons a:first-child span {
    /* border: 1px solid #67c15e; */
    box-shadow: #67c15e 0px 0px 8px;
}

.s-icons a:nth-child(2) span {
    /* border:1px solid #00aef3; */
    box-shadow: #00aef3 0px 0px 8px;
}

.s-icons a:nth-child(3) span {
    /* border: 1px solid #40b3e0; */
    box-shadow: #40b3e0 0px 0px 8px;
}

.s-icons a:first-child:hover span {
    /* border: 1px solid #67c15e; */
    box-shadow: #67c15e 0px 5px 15px;
}

.s-icons a:nth-child(2):hover span {
    /* border:1px solid #00aef3; */
    box-shadow: #00aef3 0px 5px 15px;
}

.s-icons a:nth-child(3):hover span {
    /* border: 1px solid #40b3e0; */
    box-shadow: #40b3e0 0px 5px 15px;
}

.s-icons span img {
    width: 30px !important;
    height: 30px !important;
}

.go-to-top {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: rgb(0, 0, 0, 0.5) 0px 0px 5px;
    z-index: 99999;
}

.go-to-top svg {

    color: #3ba28a;
}

.image-top {
    position: relative;
    top: -290px;
    margin-left: auto;
    max-width: 100%;

}

@media (min-width:1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1180px !important;
    }
}

@media (max-width: 1600px) {
    .core-features-inner::after {
        right: 0 !important;
        left: auto;
        width: 60px;
    }

    .core-features-inner::before {
        left: 0 !important;
        width: 60px;
    }

    .proj-block-big::before {
        width: 40% !important;
    }
}

@media (max-width:1920px) {

    .heading-outside-bg-black::after {

        height: 168px;
        top: -138px;

    }

    .heading-outside-bg::after {
        transform: translateX(-25%);
    }

    .heading-outside-bg2::after {
        transform: translateX(-35%);
    }

    .rating-app2 {
        height: 400px;
        position: relative;
        min-height: 400px;
        margin-top: 6rem;
    }

    .phone-left img {
        height: 400px !important;
    }

    .rating-2-content-yg {
        top: 70px !important;
    }

    .label-text {
        color: #1b2939;
        font-size: 32px;
    }

    .circle-rate,
    .circle-rate-2 {
        width: 120px;
        height: 120px;
        font-size: 36px;
    }

    .rating-1-content-yg {
        top: 15px !important;
    }

    .rating-2,
    .rating-1 {

        top: calc(100% / 2 - 25px);

    }

    .rating-2 img,
    .rating-1 img {
        height: 50px;
    }

    .rating-1-content {

        left: calc(25% - 103px);
        top: -180px;
    }

    .rating-2-content {

        right: calc(25% - 103px);
    }

    .tech-stack-icons img {
        max-width: 20%;
    }

    .innerpage_banner {

        margin-top: 0;
    }

    .innerpagebanner_img img {
        object-fit: cover;
        object-position: right;
        max-width: 80%;
    }

    .orange-banner-bg {
        display: grid;
        padding-top: 60px;
        align-items: center;
        padding-bottom: 60px;
        text-align: center;
        color: #fff;
        min-height: 500px;
        width: 421px;
        max-width: 100%;
    }

    .orange-banner-bg img {
        margin-bottom: 18px;
        max-width: 80% !important;
    }

    .orange-banner-bg h2 {
        font-size: 30px;
        padding: 10px;
    }

    .orange-banner-bg p {
        font-size: 20px;
        color: #fff;
        width: 80%;
        margin: auto;
    }

    .list-circle-arrow2>li {

        padding-left: 50px;
        font-size: 20px;
        line-height: 50px;
        letter-spacing: -0.1px;
        color: #666666;
    }

    .subpage-heading {
        font-size: 45px;
        font-weight: 500;
        letter-spacing: -0.9px;

        text-transform: capitalize;
    }

    .para-grey {

        font-size: 22px;
        line-height: 40px;
        font-weight: 300;
        padding-right: 25px;
        padding-top: 34px;
    }

    .img-max-md50 {
        max-width: 80% !important;
        height: auto;
    }

    .img-fluid {
        max-width: 80% !important;
    }

    .appservice_grd:nth-child(2),
    .appservice_grd:nth-child(5) {
        margin-top: 30px;
    }

    .appservice_grd {
        font-size: 24px;
        line-height: 32px;
        min-height: 155px;
        padding: 10px 15px;
    }

    .col-app {
        gap: 36px;
        display: grid;
    }

    .tech-stack-block {
        padding: 0px 0 80px 0;
    }

    .img-max-33 {
        max-width: 33% !important;
    }

    .img-top-0 {
        padding-top: 0;
    }

    .delivery-text {
        padding: 20px 50px;
    }

    .delivery-text h3 {
        font-size: 36px;
        line-height: 50px;
        color: #fff;
    }

    .delivery-text h4 {
        font-size: 30px;
        color: #fff;
    }

    .contact-us-b {
        font-size: 26px !important;
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    .proj-block-big::before {
        width: 40% !important;
    }

    .appservice_grd p {
        font-size: 24px;
    }

    .inner_subappservice {
        grid-gap: 25px;
    }

    .circle-list>li,
    .blue-circle-list>li {
        background-size: 22px 22px;
        background-position: left 6px;
    }

    .circle-list>ul>li {
        background-size: 12px 12px;
    }

    .view-more-b {
        color: #fff !important;
        font-size: 24px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-left: 36px !important;
        padding-right: 36px !important;
    }

    .f-38 {
        font-size: 26px;
        color: #666666;
    }

    .app-d {
        margin-bottom: 4rem;
    }

    .core-features-inner1 {
        padding: 80px 0px 20px 0px;
    }

    /* font-size decrease settings */
    /* .subpage-heading {
        font-size: 36px;
    }
    .para-grey {
        font-size: 18px;
        line-height: 30px;
    }.circle-list>li, .blue-circle-list>li {
        background-size: 20px 20px;
        background-position: left 6px;
    }
    .head-box-redlt {
        background: linear-gradient(to right, rgba(255, 255, 255, 0) calc(100% - 90px), #ffc7ca 90px);
        color: #050371;
    } */
    .head-box-augusta.heading-box-end::after  {
        background: #efedff ;

    }

    .head-box-ygreen.heading-box-end::after  {
        background:  #adfde6 ;

    }

    .head-box-augustaW.heading-box-end::after  {
        background:  #fff ;

    }
  

    .head-box-purple.heading-box-end::after  {
        background: #eeefff;
       
    }
    .head-box-purple {
       
        color: #2b2245;
    }
    .head-box-red.heading-box-end::after  {
        background:  #e83c30;
       
    }
    .head-box-red {       
        color: #2d2523;
    }

    .head-box-orange.heading-box-end::after  {
        background:  #ff8000 ;
       
    }
    .head-box-orange {
        
        color: #1b2939;
    }
    .head-box-agreen.heading-box-end::after  {
        background:  #8afeb5;
    }
    .head-box-agreen
    {
        color: #1b2939;
    }
    .head-box-white.heading-box-end::after {
        background:  #fff;       
    }
    .head-box-white
    {
        color: #2b2245;
    }

    .head-box-redorangelt.heading-box-end::after {
        background: #fbdddd !important;
        /* color:#0d1462 ; */
    }

    .head-box-redorangelt {
        /* background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 65px), #fbdddd 65px);     */
        color: #0d1462;
    }

    .head-box-redlt.heading-box-end::after {
        background:  #ffc7ca ;
      
    }
    .head-box-redlt {       
        color: #050371;
    }
    .head-box-redplt.heading-box-end::after {
        background:  #e1d5ff ;
    }
    .head-box-redplt {
      
        color: #1b2939;
    }
    .heading-box-end {
        padding-top: 40px;
    }

    .para-grey {
        font-size: 20px;
        line-height: 45px;
    }

    .subpage-heading {
        font-size: 42px;
    }
}

@media (max-width: 1200px) {
    .image-top {
        position: relative;
        top: -34%;
        margin-left: auto;
        max-width: 90%;
    }
    .webAppDisplay
    {
        margin-top: 4rem;
    }
    body .container {
        max-width: 960px !important;

    }
    .inner_subappservice1 .appservice_grd
    {
        min-width: 310px;
    }
    .inner_subappservice {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }
    .orange-banner-bg {
        height: 600px;
    }
    .augusta-b
    {
        height: auto;
    }
    .core-features-inner::after {
        right: 0 !important;
        left: auto;
        width: 60px;
    }

    .core-features-inner::before {
        left: 0 !important;
        width: 60px;
    }

    .zig-grid {
        display: grid;
        grid-template-columns: 1fr 25%;
        gap: 20px;
    }

    .zig-grid2 {
        display: grid;
        grid-template-columns: 25% 1fr;
        gap: 20px;
    }

}

@media (max-width:991px) {
    body .container {
        max-width: 720px !important;

    }
    .all-take-w {
        width: calc(100% - 345px);
        text-align: center;
    }
    .orange-banner-bg
    {
        width: 345px;
        height: auto !important;
        padding-bottom: 100px;
    }
    .app-store-icons
    {
        bottom:10px;
    }
    .rating-2,
    .rating-1 {
        top: calc(100% / 2 - 25px);
    }

    .d-zgrid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-bottom: 40px;
    }

    .inner_subappservice {
        grid-template-columns: 1fr;
    }

    .appservice_grd:nth-child(2),
    .appservice_grd:nth-child(5) {
        margin-top: 0;
    }

    .orange-banner-bg {
        min-height: fit-content;
    }

    .orange-banner-bg h2 {
        font-size: 36px;
        padding: 10px;
    }

    .orange-banner-bg p {
        font-size: 28px;
    }

    .w-lg-75,
    .w-lg-90 {
        width: 100% !important;
    }

    .m-minus-3 {
        top: -100px;
        right: 0;
        width: 50%;
        margin-left: auto;

    }

    .img-fluid {

        margin: auto;
        max-width: 90% !important;
    }

    .core-feature-left {
        padding-left: 20px;
    }

    .js-bw {
        justify-content: center !important;
    }

    /* .tech-stack-icons {
        justify-content: center !important;
    } */
    .appWauto::after {
        content: '';
        display: block;
        position: absolute;
        top: -55px;
        z-index: -1;
        width: 200%;
        height: 110px;
        background: #e0dced;
        border: 2px solid #fceadc;
    }

    .innerpagebanner_img img {
        object-fit: cover;
        object-position: right;
        margin-left: -29%;
    }

    .core-features-inner::before,
    .core-features-inner::after {
        width: 20px;

    }

    .xs-hide {
        display: none;
    }

    .zigzag {
        display: flex;
        gap: 5rem;
        align-items: center;

        overflow: hidden;
    }

    .m-end {
        text-align: end;
        justify-content: flex-end;
        margin-left: auto;
    }

    .appWauto {
        margin-top: 100px;
    }

    .delivery-text {
        text-align: center;
        justify-content: center;
    }

    .img-max-50 {
        max-width: 80% !important;
        height: auto;
    }

    .heading-box {
        margin-bottom: 40px;
    }
}

@media (max-width:768px) {
    .image-top {
        position: relative;
        top: 0px;
        margin-left: auto;
        max-width: 70%;
    }
    .webAppDisplay
    {
        margin-top: 4rem !important;
    }

    .delivery-text h3 {
        font-size: 30px;
        line-height: 40px;
        color: #fff;
    }

    .delivery-text h4 {
        font-size: 24px;
        color: #fff;
    }

    .contact-us-b {
        font-size: 22px !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .zig-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .zig-grid2 {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .orange-banner-bg {
        min-height: fit-content;
    }

    .orange-banner-bg h2 {
        font-size: 32px;

    }

    .orange-banner-bg img {
        margin-bottom: 0px;
    }

    .orange-banner-bg p {
        font-size: 22px;
        padding: 10px;
    }

    .innerpagebanner_img img {
        object-fit: cover;
        object-position: right;

        width: 775px;

        max-width: 100%;
    }

    .innerpagebanner_img {
        text-align: end;
        padding-top: 0;
        overflow: hidden;
        width: 100%;
    }

    .innerpage_banner {
        position: relative;
        display: flex;
        flex-flow: column;
        z-index: 2;
        margin-top: 0;
        justify-content: flex-start;
    }

    .orange-banner-bg {

        padding-top: 90px;
        padding-bottom: 90px;
        margin: auto;
        margin-top: -150px;

        text-align: center;

    }

    .d-zgrid {
        display: grid;
        grid-template-columns: 1fr;
    }

    .m-minus-3 {
        top: 0;
    }

    .img-max-25 {
        max-width: 50% !important;
        height: auto;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .tech-stack-icons img {
        max-width: 50%;
    }

    /* .l-h-e
    {
        margin-top: -100px;
    } */
    .core-features-inner::before,
    .core-features-inner::after {
        width: 20px;

    }

    .xs-hide {
        display: none;
    }

    .list-circle-arrow2 {
        padding-left: 0;
    }

    .zigzag {
        flex-wrap: wrap;
    }

    .img-max-50 {
        max-width: 80% !important;
        height: auto;
    }

    .fixed-icons,
    .s-icons {
        display: none !important;
    }
}

@media (max-width:600px) {
    .inner_subappservice {
        grid-template-columns: auto !important;
    }

    .f-38 {
        font-size: 20px !important;
        color: #666666;
    }

    .list-circle-arrow2>li {
        background: url(../Assest/Images/circle-arrowb.png) no-repeat;
        background-size: 14px 14px;
        background-position: left 14px;
        padding-left: 40px;
        list-style-type: none;
        font-size: 24px;
        font-weight: 300;
        text-indent: 0px;
        line-height: 46px;
        letter-spacing: -0.1px;
        color: #666666;
    }

    .blue-circle-list>li {
        background-size: 18px 18px !important;
    }

    .circle-list>li {
        background-size: 18px 18px !important;
    }

}

@media (max-width:570px) {

    .img-max-25 {
        max-width: 80% !important;
        height: auto;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .circle-rate-2,
    .circle-rate {
        width: 100px;
        height: 100px;
        font-size: 26px;
    }

    .label-text {
        color: #1b2939;
        font-size: 26px;
    }

    .rating-2-content {
        position: absolute;
        right: calc(15% - 72px);
        width: -moz-fit-content;
        width: 200px;
    }

    .rating-1-content {
        position: absolute;
        left: calc(15% - 72px);
        width: -moz-fit-content;
        width: 200px;
        top: -168px;
    }

    .rating-2-content-yg {
        top: 80px !important;
    }

    .rating-1-content-yg {
        top: 40px !important;
    }

    .rating-app2 {
        height: 400px;
        position: relative;
        min-height: 400px;
        margin-top: 6rem;
    }

    .navbar-brand {
        width: calc(100% - 80px);
    }

    .navbar-brand img {
        max-width: 100%;
    }

    .rating-1 {
        left: 15%;
    }

    .rating-2 {
        right: 15%;
    }

    .inner_subappservice1 .appservice_grd {
        max-width: 100%;
        min-width: fit-content;
        width: 100%;
        margin: auto;
    }

    .orange-banner-bg {
        padding-top: 50px;
        padding-bottom: 50px;
        margin: auto;
        margin-top: -200px;
        text-align: center;
    }

    .orange-banner-bg2 {
        padding-top: 50px;
        padding-bottom: 50px;
        margin: auto;
        margin-top: -92px;
    }

    .m-s0 {
        margin-top: 0;
    }

    .margin-top-50 {
        margin-top: 0;
    }

    .heading-outside-bg::after {
        height: 80px !important;
        top: -68px !important;
    }

    .display-app .project-pad {
        padding-top: 45px;
    }

    .subpage-heading {
        font-size: 54px;
    }

    /* .innerpagebanner_img img
    {
        object-fit: cover;
        object-position: right;
        margin-left: -68%;
    } */
    .subpage-heading {
        font-size: 32px;
    }

    .para-grey {
        color: #666666;
        font-size: 16px;
        line-height: 32px;

        padding-right: 5px;
        padding-top: 36px;
    }

    .delivery-text h3 {
        font-size: 22px;
        line-height: 39px;
        color: #fff;
    }

    .delivery-text h4 {
        font-size: 18px;
    }

    .contact-us-b {
        color: #2d2523 !important;
        font-size: 21px !important;
        background: #fff !important;
        text-transform: uppercase;
        padding-left: 38px !important;
        padding-right: 38px !important;
    }

    .view-more-b {
        color: #fff !important;
        font-size: 21px !important;
        background: #867ef0 !important;
        text-transform: uppercase;
        padding-left: 38px !important;
        padding-right: 38px !important;
    }

    .bg-bbdark {
        background: #0d1462 !important;
    }

    .delivery-text {
        padding: 0 0px;
    }

    /* .head-box-augusta
    {
        background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 70px), #efedff 70px);
        
    }
    .head-box-ygreen
    {
        background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 70px), #adfde6 70px);
        
    }
    .head-box-augustaW
    {
        background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 70px), #fff 70px);
        
    }
    .head-box-purple {
        background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 70px), #eeefff 70px);
        color: #2b2245;
    }
    .head-box-red
    {
        background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 70px), #e83c30 70px);
        color: #2d2523;
    }
    .head-box-orange
    {
        background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 70px), #ff8000 70px);
        color: #1b2939;
    }
    .head-box-agreen
    {
        background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 70px), #8afeb5 70px);
        color: #1b2939;
    }
    .head-box-white
    {
        background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 70px), #fff 70px);
        color: #2b2245;
    }

    .head-box-redorangelt
    {
        background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 70px), #fbdddd 70px);    
        color:#0d1462 ;
    }
    
    .head-box-redlt
    {
        background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 70px), #ffc7ca 70px);
        color: #050371;
    }
    .head-box-redplt
    {
        background: linear-gradient(to right, rgba(255,255,255,0) calc(100% - 70px), #e1d5ff 70px);
        color: #050371;
    } */

    .heading-box-end {
        padding-top: 36px;
    }



    .techstack-app {
        padding: 40px 10px;
        background: #fff;
    }

    .m-t-m350 {
        margin-top: -165px;
        padding-top: 195px;
        padding-bottom: 100px;
    }

    .project-idea {
        padding: 30px 0;
        overflow: hidden;
    }

    .col-app {
        gap: 20px;
        display: grid;
    }

    .core-feature-left {
        padding-left: 0px;
    }

    .core-features-inner {
        padding-left: 0;
    }

    .core-features-inner::before,
    .core-features-inner::after {
        width: 11px;

    }

    .core-features-inner::after {
        right: -21px !important;

    }

    .core-features-inner::before {
        left: -21px !important;

    }

    .circle-list>li,
    .circle-list>ul>li {
        background-position: left 5px;
    }

    .appservice_grd p {
        font-size: 20px;
        line-height: 38px;
    }

    .appservice_grd {
        min-height: auto;
    }

    .f-38 {
        font-size: 24px;
        color: #666666;
    }

    .list-circle-arrow {
        line-height: 40px;
    }

}
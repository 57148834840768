.TopDetailsBlog ,.BottomDetailsBlog{
    display: grid;
    grid-template-columns: 25% auto;
     border: 1px solid #cacbcb;
}
.leftdetails{
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    border-right: 1px solid #cacbcb;
    height: 100%;
    min-height: 6rem;
    padding: 1.5rem 2rem;
}
.blog_date h4{
    background: -webkit-linear-gradient( #4dc0de , #1a377a );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 20px;
}
.blog_nameshow a{
    padding: .125rem .6875rem;
    font-size: .875rem;
    color: #1a377a;
    text-align: center;
    text-transform: capitalize;
    background-color: rgba(21, 50, 43, .2);
    border-radius: .75rem;
    font-weight: 500;
    line-height: 1.5;
    text-decoration: none;
    transition: border-radius .2s, border-color .2s, color .2s, background-color .2s;
    position: relative;
}

.Rytdetails{
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    flex-flow: column;
    padding: 1.5rem 2rem;
    display: flex;
}
.Rytdetails span {
    color: #000;
    font-size: 16px;
    font-weight: 400;
}
.Rytdetails span a{
    background: -webkit-linear-gradient( #4dc0de , #1a377a );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.Rytdetails h1 {
    margin-bottom: .75rem;
    font-size: 3rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -1px;
    color: #1a377a;
}
.over{
    overflow: visible !important;
}
.bottomleft{
    border-right: 1px solid #cacbcb;
    padding: 1.5rem 2rem;
    position: sticky;
    top: 10rem;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.bottomRyt{
    width: 100%;
}
.blogimg{
    width: 100%;
}
.blogimg img{
    width: 100%;
}
.blog-summary-container {
    background-image: linear-gradient(rgba(250, 247, 243, .13), rgba(21, 50, 43, .15));
    border-radius: 1rem;
    width: 100%;
    max-width: 100%;
    margin-bottom: 2rem;
    padding: 1.5rem;
    position: relative;
}
.paragraph {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5em;
    margin-bottom: 0;
}
.timeline-connector {
    opacity: 1;
    background-image: linear-gradient(rgba(46, 123, 224, 0.904), rgba(85, 211, 233, 0.548));
    width: .1875rem;
    height: 9.375rem;
    margin-bottom: 2.5rem;
    margin-left: -.09375rem;
    position: relative;
    top: 0%;
    bottom: auto;
    left: 50%;
    right: auto;
}
.blogLinks {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.blogLinks a{
    opacity: .6;
    color: #000;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: 100%;
    font-size: .74rem;
    font-weight: 500;
    text-decoration: none;
    transition: all .2s cubic-bezier(.455,.03,.515,.955);
}
.blogLinks a:hover{
    color: #1a377a;
    opacity: 1;
}
#major-achievements{
    padding: 1.5rem 2rem;
}
#major-achievements h2{
    color: #1a377a;
}

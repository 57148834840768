.BlogSection{
    background-color: #f5f5f5;
    padding: 74px 0px 100px 0px;
    
}
.Blogwrapper{
    width: 100%;
    max-width: 93.75rem;
    margin: 0 auto;
}
.blogHeading{
    border: 1px solid #cacbcb;
    padding: 12px 30px;
}

.blogTablist{
    border: 1px solid #cacbcb;
    
}

.Blogmail .search {
    border-left: 2px solid #cacbcb;
    flex: 1;
    align-items: stretch;
    width: 100%;
    margin-bottom: 0;
    margin-left: auto;
    font-size: 1.5rem;
    display: flex;
    height: 100%;
}
.Blogmail .search-input {
    color: #000;
    background-color: transparent;
    border: 0 #000;
    height: 100%;
    min-height: 100%;
    margin-bottom: 0;
    padding: 1.5rem;
    width: 100%;
    font-size: 1.5rem;
    line-height: .0875rem;
    transition: border-radius .1s;
    outline: none;
}
.Blogmail .button {
    color: #fff;
    background: #2a629a;
    border: none;
    text-align: center;
    border-radius: .25rem;
    padding: .5rem 1.5rem;
    font-weight: 500;
    transition: border-radius .2s, border-color .2s, color .2s, background-color .2s;
    position: relative;
    box-shadow: none;
}
.ShowFeature {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid #cacbcb;
}
.ShowFeature:hover {
    background-color: #eaedff;
}
.ShowFeature:hover .redbtn a{
    border: .09375rem solid #2a629a;
    color: #fff;
    background-color: #2a629a;
}
.headText {
    margin-bottom: .75rem;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -1px;
    color: #1a377a;
    text-align: center;
}
.fetureImg {
    margin-bottom: 40px;
}
.fetureImg img{
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
    border: 1px solid #cacbcb;
    max-height: 400px;
}

.redbtn a{
    border: .09375rem solid #2a629a;
    color: #000;
    text-align: center;
    background-color: transparent;
    border-radius: .25rem;
    padding: .375rem 1.15rem;
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.5;
    text-decoration: none;
    transition: border-radius .2s, border-color .2s, color .2s, background-color .2s;
    position: relative;
}
.redbtn a:hover{
    border: .09375rem solid #2a629a;
    color: #fff;
    background-color: #2a629a;
}




.rytfeature{
    display: grid;
    grid-template-rows: 1fr 1fr;

}


.Blog_Card {
    padding: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 22px;
    height: 100%;
    border: 1px solid #cacbcb;
}
.Blog_Card:hover .blogcardImg img{
    transform: scale(1.1);
    transition: transform 0.5s;
}
.Blog_Card:hover{
    background-color: #eaedff;
    transition: all .2s ;
}
.Blog_Card:hover .redbtn a{
    border: .09375rem solid #2a629a;
    color: #fff;
    background-color: #2a629a;
}

.blogcardImg{
    width: 100%;
    overflow: hidden;
    border-radius: 1.5rem;
}

.blogcardImg img{
    width: 100%;
    border-radius: 1.5rem;
    border: 1px solid #cacbcb;
    height: 100%;
    min-height: 180px;
    max-height: 180px;
}
.blgtext {
    letter-spacing: -.0125rem;
    text-transform: none;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.1;
    color: #1a377a;
}

.BlgHEadings {
    border: 1px solid #cacbcb;
    padding: 1.5rem;
}
.BlgHEadings h2{
    opacity: .75;
    letter-spacing: .0625rem;
    text-transform: uppercase;
    margin: 0;
    align-self: auto;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.2em;
    color: #1a377a;
}
.Blgprdt{
    display: grid;
    grid-template-columns: repeat(4,1fr) ;
}

.BlogForms{
    background: #e8f1ff !important;
}
.BlgFommDiv{
    width: 100%;
    max-width: 93.75rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 110px;

}
.blogImages img{
    width: 100%;
    overflow: hidden;
}
.blogcontact {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 100%;
    gap: 50px;
}
.mobileview{
    display: none;
}
.blogcontact h2 {
    font-size: 35px;
    font-weight: 300;
    line-height: 45px;
}

/* temp  */
.CommingSoon{
    padding: 150px 0px 100px 0px;
    background: #eaedff !important;
    display: flex;
    align-items: center;
}
.SoonData{
    display: grid;
    grid-template-columns: 40% 60%;
    grid-column-gap: 25px;
}
.soongoing{
    display: flex;
    align-items: center;
    gap: 12px;
}
.gobtn{
    display: flex;
    align-items: center;
    gap: 5px;
}
.leftSoon img{
    width: 100%;
}
.RytSoon{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-direction: column;
}
.RytSoon img{
    width: 100%;
    max-width: 570px;
}
.RytSoon h6{
    color: #2a2a2a;
    font-size: 29px;
    font-weight: 400;
    text-align: center;
    padding-top: 15px;
    margin: 0;
}
.soongoing span{
    color: #706d79;
    font-weight: 400;
    font-size: 15px;
}
span.gobtn a {
    color: #183371;
    font-weight: 600;
    font-size: 18px;
}


/* Responsive Started  */
@media only screen and (max-width: 1499px){
    .Blogwrapper {
        max-width: 78.75rem;
    }
    .BlgFommDiv {
        max-width: 78.75rem;
        grid-column-gap: 50px;
    }
    .blgtext {
        font-size: 1.2rem;
        line-height: 1.4;
    }
    .blogcontact h2 {
        font-size: 31px;
        line-height: 40px;
    }

}

@media only screen and (max-width: 1199px){
    .Blogwrapper {
        max-width: 60.75rem;
    }
    .BlgFommDiv {
        max-width: 60.75rem;
        grid-column-gap: 50px;
    }
    .Blogmail .search-input {
        font-size: 1.2rem;
    }
    .Blogmail .button {
        font-size: 1.2rem;
    }
    .headText {
        font-size: 1.7rem;
    }
    .Blgprdt {
        grid-template-columns: repeat(3, 1fr);
    }
    .BlgHEadings h2 {
        font-size: 1.1rem;
    }
    .blogcontact h2 {
        font-size: 24px;
        line-height: 30px;
        margin: 0;
    }

}

@media only screen and (max-width: 810px){
    
    .Blgprdt {
        grid-template-columns: repeat(2, 1fr);
    }
    .blogcontact h2 {
        font-size: 18px;
        line-height: 24px;
    }
    .blogcontact {
        gap: 25px;
    }

}

@media only screen and (max-width: 640px){
    
    .headText {
        font-size: 1.4rem;
    }
    .Blogmail .search-input {
        font-size: 1.0rem;
        padding: 1.0rem;
    }
    .Blogmail .button {
        font-size: 1.0rem;
    }
    .blgtext {
        font-size: 1.1rem;
    }

}

@media only screen and (max-width: 540px){
    
    .Blgprdt {
        grid-template-columns: repeat(1, 1fr);
    }
    .Blog_Card {
        align-items: center;
    }
    .blogcardImg img {
        min-height: 230px;
        max-height: 230px;
    }
    .blgtext {
        font-size: 1.5rem;
    }
    .desktopview {
        display: none;
    }
    .mobileview{
        display: block;
    }
    .blogImages img {
        max-width: 290px;
    }
    .BlgFommDiv {
        display: flex;
        flex-direction: column;
        gap: 55px;
        align-items: center;
        padding: 25px;
    }
    .blogcontact {
        gap: 35px;
    }
    .blogcontact h2 {
        font-size: 22px;
        line-height: 30px;
    }

}

@media only screen and (max-width: 390px){

    .Blogmail .search {
        flex-direction: column;
    }
    .blogTablist {
        margin-top: 20px;
    }
    .blogHeading {
        border: 1px solid transparent;
    }
    .headText {
        font-size: 1.2rem;
    }
    .BlgHEadings h2 {
        font-size: 0.9rem;
    }
    .blgtext {
        font-size: 1.2rem;
    }
    .blogcontact h2 {
        font-size: 18px;
        line-height: 25px;
    }



}
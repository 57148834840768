
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&family=Titillium+Web:wght@200;300;400;600;700;900&display=swap');



a{color: inherit ; text-decoration: none !important;}

body { overflow-x: hidden;font-family: 'Readex Pro', sans-serif !important;}
p{ font-size: 16px;}
body .container {

   margin: auto;padding: 0 20px;width: 100%;
  
  }




.grad_btn {font-family: "Montserrat", sans-serif;font-weight: 500;font-size: 16px;letter-spacing: 1px;display: inline-block;padding: 10px 30px; border-radius: 50px;
  transition: 0.5s; color: #fff;background: #1acc8d;text-decoration: navajowhite !important;}
.grad_btn:hover {background: #17b57d; color:#fff;}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  border-radius: 1px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
border: 3px solid transparent;
 background-clip: content-box;
  background-color: #162d61;;
}

.navbar.bg-white .navbar-brand img {
  width: 197px;
  margin-top: 0;
}
.banner-sub
{
  margin-top: 108px;
}
.banner {background:url(../Assest/Images/banner1.jpg); background-size: cover; position: relative;padding: 100px 0 40px 0;margin-bottom: 100px;}
/* .banner::before { background: rgba(0, 0, 0, 0.89); content: ""; width:100%; height: calc(100% - 76px); position: absolute;} */
.up-down{ animation: up-down linear 6s;animation-iteration-count: infinite; transform-origin: 50% 50%;-webkit-animation: up-down linear 6s; 
  -webkit-animation-iteration-count: infinite;-webkit-transform-origin: 50% 50%;-moz-animation: up-down linear 6s; 
  -moz-animation-iteration-count: infinite; -moz-transform-origin: 50% 50%; -o-animation: up-down linear 6s; -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 50%;-ms-animation: up-down linear 6s;-ms-animation-iteration-count: infinite;-ms-transform-origin: 50% 50%;}
  @keyframes up-down{
    0% {
      transform:  translate(1px,20px)  ;
    }
    24% {
      transform:  translate(1px,30px)  ;
    }
    50% {
      transform:  translate(1px,12px)  ;
    }
    74% {
      transform:  translate(1px,22px)  ;
    }
    100% {
      transform:  translate(1px,22px)  ;
    }
  }
  
  @-moz-keyframes up-down{
    0% {
      -moz-transform:  translate(1px,20px)  ;
    }
    24% {
      -moz-transform:  translate(1px,30px)  ;
    }
    50% {
      -moz-transform:  translate(1px,12px)  ;
    }
    74% {
      -moz-transform:  translate(1px,22px)  ;
    }
    100% {
      -moz-transform:  translate(1px,22px)  ;
    }
  }
  
  @-webkit-keyframes up-down {
    0% {
      -webkit-transform:  translate(1px,20px)  ;
    }
    24% {
      -webkit-transform:  translate(1px,30px)  ;
    }
    50% {
      -webkit-transform:  translate(1px,12px)  ;
    }
    74% {
      -webkit-transform:  translate(1px,22px)  ;
    }
    100% {
      -webkit-transform:  translate(1px,22px)  ;
    }
  }
  
  @-o-keyframes up-down {
    0% {
      -o-transform:  translate(1px,20px)  ;
    }
    24% {
      -o-transform:  translate(1px,30px)  ;
    }
    50% {
      -o-transform:  translate(1px,12px)  ;
    }
    74% {
      -o-transform:  translate(1px,22px)  ;
    }
    100% {
      -o-transform:  translate(1px,22px)  ;
    }
  }
  
  @-ms-keyframes up-down {
    0% {
      -ms-transform:  translate(1px,20px)  ;
    }
    24% {
      -ms-transform:  translate(1px,30px)  ;
    }
    50% {
      -ms-transform:  translate(1px,12px)  ;
    }
    74% {
      -ms-transform:  translate(1px,22px)  ;
    }
    100% {
      -ms-transform:  translate(1px,22px)  ;
    }
  }

  .banner_img img { width: 107%;margin-left: 20%; margin-bottom: -130px;}
  .inner_banner { display: grid; grid-template-columns: 55% auto; position: relative; z-index: 2;align-items: center;grid-gap: 30px;}
  /* .main_wave{ --wav1:url('https://i.postimg.cc/43KhFcRw/wave.png');position: absolute;width: 100%;bottom: 0; }
  .main_wave.dark{--wav1:url('https://i.postimg.cc/FRBCX3YR/wave2.png');}					
  .waves{position:relative;width: 100%; }					
  .waves .wave{position: absolute; bottom: 0; left: 0; width: 100%; height: 100px; background: var(--wav1); background-size: 1000px 100px;}               
  .waves .wave.wave1{animation: animate 6s linear infinite; opacity: .7; animation-delay: 2s;}
  .waves .wave.wave2{ animation: animate 10s linear infinite; opacity: .6; animation-delay: 4s;}
  .waves .wave.wave3{ animation: animate 14s linear infinite;opacity: 1;animation-delay: 0s;}
  @keyframes animate {
    0%{
       background-position-x: 0;
      }
      100%{
        background-position-x: 1000px;
      }
  }
  .dark-toggle{position: absolute; z-index: 1000; transform:translate(50%, 50%); border: 1px solid white; background: black; padding: 2px 5px; transition: all 0.5s ease-in-out;}
  .dark-toggle:hover{border: 1px solid black; padding: 2px 5px; color: black; background-color: white;}
  a.dark-toggle{color:white; text-decoration:none;} */
  .port-app
  {
    display: grid;
    gap: 3rem;

  }
 .heading {margin: 0 0 40px 0;font-size: 63.72px;font-weight: 500;line-height: 56px;color: #2e2c2c;
  position: relative;
  background: #fff;
  transition: transform .3s;
  z-index: 3;
  border-left: 6px solid #1a377a;
  padding-left: 22px;
  padding-top: 30px;
  padding-bottom: 30px;
  letter-spacing: -0.9px;
  background: linear-gradient(to left, rgba(255,255,255,0) calc(100% - 165px), #ebfffa 165px);
  text-transform: uppercase;
}
.list-circle-arrow
{
  list-style-image: url(/src/Assest/Images/circle-arrow.png);
  font-size: 23px;
  font-weight: 300;
  text-indent: 10px;
  line-height: 52px;
  letter-spacing: -0.1px;
}
.solution-head
{
  font-size: 34px;
  color: #1b2939;
  margin-top: 63px;
}
.book_your_call
{
  width: auto;
  padding-top: 15px;
  padding-bottom: 22px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 37px;
  color: #fff !important;
  background: #1a377a;
  font-size: 27px;
  font-weight: 300;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 0px #000;
  display:inline-block;
}


 .heading span {color: #1a377a;font-size: 62px;font-weight: 500;line-height: 75px;margin-top: 11px;display: block;}
 .banner_text p { color: #393939;font-weight: 300;font-size: 25px;}
 .banner_text h3 {color: #181818;margin-bottom: 15px;font-size: 40px;line-height: 48px;}
 .and_ios_icon span {display: flex;gap: 20px;margin: 22px 0;}
 .book_call {color: #1a377a;font-size: 25px;font-weight: 500;text-decoration: none !important;}
 .book_call:hover {color: #1a377a;}
 .book_call img { margin-left: 20px;transition: 0.6s;}
 .book_call:hover img { margin-left: 10px;}
.collaborate-sec{padding:10px 0px 30px 0; overflow: hidden;}
.collaborate-sec .head_para {
  
  text-align: center;
  
  max-width: 100%;
}


 .bar_heading span::after {content: "";position: absolute;width: 49px;height: 4px;background: #1a377a;margin-left: 0;top: -23px;left: 0;}
 .bar_heading span { font-size: 30px; font-weight: 500;padding: 0;line-height: 38px;margin: 0 0 0px 0;letter-spacing: 2px;text-transform: capitalize;color: #666666;
  display: block;position: relative;margin-bottom: 6px;}
.bar_heading {margin: 0; font-size: 45px; line-height: 55px; font-weight: 700;text-transform: uppercase; font-family: "Poppins", sans-serif; color: #010483;}
.bar_heading_dn {text-align: center;font-size: 48px;font-weight: 600;color: #1a377a;position: relative;}
.bar_heading_dn::after {content: "";position: absolute;width: 49px;height: 4px;background: #1a377a; bottom: -15px; left: 0; right: 0; margin: auto;}







.inner_port_text .slick-slide .thumb img {width: 100%; padding: 0 6%; cursor: pointer;}
.inner_port_text {display: grid;grid-template-columns: 310px auto; grid-gap: 40px;align-items: flex-start;}
.inner_port_text .left_text::after {content: ""; position: absolute; width: 100%; height: calc(100% - 20px); background: #ffe9d2; top: 0; z-index: -1; left: -18px; border: 2px solid #f1d3b4; 
 border-radius: 12px;}
.inner_port_text .left_text { position: relative;z-index: 5555;padding: 25px 0 0 6px;}
.inner_port_text .carousel-inner{ border-radius: 12px;}
.portfolio_new{ background: url(../Assest/Images/slide_bg.png); padding: 80px 0;}
.inner_portfolio_new h2 {font-size: 48px;color: #1a377a;font-weight: 600;letter-spacing: -1px;line-height: 55px;}
.inner_portfolio_new h3 {font-size: 38px;color: #666;position: relative;line-height: 42px;}
.inner_portfolio_new h3:after { content: ""; position: absolute; width: 50px; height: 5px; background: #1a377a; left: 0; top: -21px;}
.inner_portfolio_new {position: relative;padding-bottom: 80px;}
.port_text { padding: 20px; background: #fff; box-shadow: 0px 0px 18px -5px #d9d7d7;margin: 55px 15px 15px 15px;border-radius: 4px;overflow: hidden;}
.right_text {position: relative;}
.dated span { font-size: 19px;display: block;}
.dated { font-size: 25px; color: #fff; background: #2a4583; text-align: center; width: 114px; padding: 12px 0; position: absolute; right: -41px; top: -20px;}
.right_text h3 {font-size: 40px;color: #2a4583;font-weight: 600 !important; margin: 0;}
.right_text h1 {font-size: 30px; color: #3c3c3c; position: relative;}
.right_text h5 { font-size: 24px;color: #3c3c3c;margin-bottom: 22px;margin-top: 5px;font-weight: 400;}
.right_text h1::after {content: ""; position: absolute;width: 50px;height: 4px;background: #2a4583;top: 18px;margin-left: 14px;}
.col_g h4 {font-size: 25px;color: #2a4583;font-weight: 600;}
.main_grds {display: grid;grid-template-columns: 1fr 1fr;padding-top: 40px;overflow-wrap: anywhere;}
.col_g ul li {font-size: 19px;color: #464646;font-weight: 300;padding-bottom: 5px;position: relative;}
.col_g ul li::marker {color: #2a4583;}
.col_g ul {padding-left: 20px;}
.portfolio_new .slick-next::before, .portfolio_new .slick-prev::before {font-size: 20px;line-height: 1;opacity: 1; color: #2a4583 !important;}
.portfolio_new .slick-slider.thumb_slider { margin: 0 12px;}
.thumb_slider .slick-slide img { margin: auto;width: 90% !important;cursor: pointer;}
.slick-slider.big_slider .slick-next, .slick-slider.big_slider .slick-prev {display: none !important;}
.left_text .carousel-control-next-icon,.left_text .carousel-control-prev-icon
{
  filter: brightness(0.2) sepia(1) hue-rotate(180deg) saturate(5) 
  /* drop-shadow(0px 0px 8px rgb(178, 180, 175)) */
  ;
    fill: #060669;
    /* filter: brightness(0.5) sepia(1) hue-rotate(140deg) saturate(6); */
    /* -webkit-filter: hue-rotate(276deg) saturate(0.1) saturate(6.25) brightness(73%);
    filter: hue-rotate(276deg) saturate(0.1) saturate(6.25) brightness(73%); */
  
}
.left_text .slide
{
  border: 2px solid #b7ebdf;
    border-radius: 12px;
}
.left_text .carousel-control-next-icon
{
  margin-right: -70px;
}
.left_text .carousel-control-prev-icon
{
  margin-left: -70px;
}
.Stack {padding: 80px 0;overflow: hidden;}
.inner_port_text .left_text {position: relative; z-index: 5555;padding: 25px 0 0 6px;max-width: 500px; margin:0 auto;}
/* .slick-track {padding-top: 79px;} */
.head_para {font-size: 25px;text-align: center;color: #666666; font-weight: 300; max-width: 1199px; margin: auto; padding-top: 35px; line-height: 34px;}
.slick-slider.stack_slider .slick-slide img {display: block;width: 90%; margin: auto;}
.slick-slider.stack_slider .slick-next::before, .slick-slider.stack_slider .slick-prev::before { opacity: 1;color: #1a377a;}
.slick-slider.stack_slider {margin-top: 60px;}
.innerstack_sl { background: #f8f8f8; box-shadow: 8px 0px 0px 30px #f8f8f8;}
.stack_sl { margin: 0 15px;}



.app_service {padding: 80px 0; overflow-x: hidden;}
.service_grd { display: grid;grid-template-columns: 1fr 1fr 1fr;margin-top: 65px;}
.serv {border-bottom: 1px solid #c9cbcb;border-right: 1px solid #c9cbcb;padding: 20px 20px;}
.serv:nth-child(3) {border-right: 0px solid #c9cbcb;}
.serv:nth-child(4) {border-bottom: 0px solid #c9cbcb;}
.serv:nth-child(5) {border-bottom: 0px solid #c9cbcb;}
.serv:nth-child(6) {border-bottom: 0px solid #c9cbcb;  border-right: 0px solid #c9cbcb;}
.serv h3 { color: #1a377a;font-size: 25px;line-height: 32px; font-weight: 400;}
.serv p { color: #222222;font-size: 19px;line-height: 25px;font-weight: 300;}


.testi_monial .head_para {padding: 0px;text-align: left;margin: 0; max-width: 700px;}
.testi_monial {padding: 80px 0;}


.faq .head_para {padding: 0px;text-align: left;margin: 0; }
/* .faq_list{  max-height: 615px;    overflow: auto;} */
.showMore {
  color: #fff;
  background: #1a377a;
  font-size: 14px;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 0px #000;
  display: inline-block;
  text-transform: uppercase;
  margin-top: 20px;
  text-decoration: none;
}
.showMore:hover {
  color: #fff;
  background: #1a377a;
  font-size: 14px;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 0px #000;
  display: inline-block;
  text-transform: uppercase;
  margin-top: 20px;
  text-decoration: none;
}
.faq h1.bar_heading span {font-size: 45px;font-weight: 600;line-height: 52px; color: #2a2a2a;margin-bottom: 6px;}
.inner_faq {display: grid;grid-template-columns: 1fr 1fr;grid-gap: 23px;}
.faq .head_para {padding: 0px;text-align: left;margin: 0;max-width: 614px;}
.faq {padding: 60px 0;}
.faq .accordion-button::after{ background: url(../Assest//Images/open.png) !important;background-size: 100% !important;}
.faq .accordion-button:focus { box-shadow: none !important;border-color:#010483;}
.faq .accordion-button {font-size: 22px; background: transparent !important; border-top: 2px solid #010483; color: #1a377a !important;box-shadow: none !important;
  padding-left: 0px;padding-right: 0px;}
.faq .accordion-body{  padding-left: 0px;padding-right: 0px;padding-top: 7px; padding-bottom: 25px;}
.faq .accordion-flush .accordion-item {border-bottom:1px solid #010483 !important;}
.faq .accordion-flush .accordion-item:last-child {border-bottom:0px solid #010483 !important;}
.faq .accordion-flush .accordion-item {border-bottom: 0px solid #010483 !important;}

.appDevProcess
{
  padding: 60px 0;
  overflow-x: hidden;
}
.inner_testi_monial 
{
  max-width: 100%;
  overflow-x: hidden;
}
.appDevProcess .innerapp_service {
  display: flex;
  gap:55px;
  position: relative;
  padding-top: 55px;
  flex-wrap: wrap;
  justify-content: center;
}
.app_process {
  background: #ffe4de;
  border: 2px solid #edc6bd;
  border-radius: 5px;
  min-width: 160px;
  min-height: 160px;
  max-width: 160px;
  text-align: center;
  padding: 10px 0.5rem;
  position: relative;
}
.app_process::after {
  content: '';
  position: absolute;
  display: block;
  left: calc(100% + 15.5px);
  top: calc(50% - 7.5px);
  width: 31px;
  height: 15px;
  background: url(../Assest/Images/arrow_icon.png);
}
.app_process:last-child:after {
  display: none;
}
.app_process img {
  margin-bottom: 10px;
  max-width: 100%;
}
.app_process h3
{
  color: #1a377a;
  max-width: 100%;
  font-size: 22px;
  font-weight: 300;
}


.Contact {padding: 90px 0px 60px 0;}
.inner_react_service {display: grid;grid-template-columns: 1fr 1fr 1fr;grid-gap: 40px; position: relative;padding-top: 55px;}
.react_service_grd { position: relative; background: #fff; transition: transform .3s; z-index: 3;border-left: 6px solid #1a377a; padding-left: 24px;
background: linear-gradient(to left, #fff 60%, #ebfffa 40%);}
.grd_txt {padding: 21px 0px 10px 0px; }
.react_service_grd h1 {font-size: 30px;font-weight: 700;text-transform: uppercase;color: #2c2c2c;max-width: 350px;padding-bottom: 0px; padding-top: 0; position: relative; 
  margin-bottom: 17px;}
.react_service_grd p {font-size: 18px; color: #6b6b6b;}
.Contact .head_para {padding: 0px;text-align: left;margin: 0; }
.captcha-d
  {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
#CAPTCHA {
  width: 250px;
  height: 76px;
  background:linear-gradient(to left, #ececec, silver, #ececec);
 
  color: #089;
  font-size: 45px;
  line-height: 76px;
  border: 2px solid #888;
  border-radius: 10px;
  
  pointer-events: none;
  text-align: center;
  transition: all .5s ease-in-out;
  
}
#CAPTCHA span
{
  transform: skew(20deg);
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
}
.reload {
  
  
  text-align: center;
  padding: 5px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  border: 1px solid #6aae9e;
  border-radius: 3px;
  color: #72c9b4;
  transition: all .5s ease-in-out;
}

.reload:hover {
  background: #f2f2f2;
}



  .contact_form .form-container {width: 100%;padding: 10px;margin: auto; }
  .contact_form .form-row {position: relative;margin-top: 45px;}
  .contact_form .form-label {position: absolute;top: 11px;left: 20px;color: #72c9b4;cursor: text;transition: all .15s ease-in-out 0s;}
  .flag-input input, .contact_form .form-textbox,   .contact_form .form-submit {width: 100%;padding: 9px 20px;height: 47px !important;border: 1px solid #6aae9e; border-width: 0 0 1px 0; background: transparent; color: #72c9b4;}
  .contact_form .form-textbox:focus-visible, .contact_form .form-textbox2:focus-visible {box-shadow: none !important;border: none;outline: 0px solid #72c9b4;
    border: 1px solid #599b8c; border-width: 0 0 2px 0;}
  .contact_form .form-textbox2 {width: 100%;padding: 9px 20px;height: 100px !important;}
  .contact_form .form-textbox:focus ~ .form-label, .contact_form .form-textbox2:focus ~ .form-label,   .contact_form .form-textbox:valid ~ .form-label {top: -1.5em;left: 0;
    font-size: .8em;color: #72c9b4;cursor: pointer;}
  .contact_form .form-submit { background-color: transparent; font-weight: 700; transition: opacity .15s ease-in-out 0s; border: 2px solid #1a377a; width: 200px;
  color: #1a377a;border-radius: 9px;}
  .contact_form .form-submit:hover {opacity: .75;}
  .form__field {font-family: inherit; width: 100%; border: 0; border: 1px solid #6aae9e; outline: 0; font-size: 16px; color: #72c9b4; padding: 16px 25px;border-radius: 6px;
  background: transparent; transition: border-color 0.2s;}
  .form__field::placeholder { color: transparent;}
  .form__field:placeholder-shown ~ .form__label {font-size: 16px; cursor: text; top: 20px;left: 20px;font-size: 16px;}
  label.form__label,
  .form__field:focus ~ .form__label { position: absolute;top:-19px; display: block; transition: 0.2s; font-size: 13px; color: #72c9b4;left: 0px;}
  .form__field:focus ~ .form__label {color: #72c9b4;}
  .form__field:focus {border-bottom: 2px solid #009788;}
  .select-text {border-bottom: 1px solid rgba(0,0,0, 0.12);background: transparent;border: 1px solid #000; border-width: 0 0 2px 0;}
  .select-text:focus { outline: none;border-bottom: 1px solid rgba(0,0,0, 0);}
  .select .select-text { appearance: none;-webkit-appearance:none}
  .select:after {position: absolute;top: 18px;right: 10px;width: 0;height: 0;padding: 0;content: '';border-left: 6px solid transparent;
  border-right: 6px solid transparent;border-top: 6px solid #72c9b4;pointer-events: none;}
  .select-label {color: #72c9b4;font-size: 18px;font-weight: normal; position: absolute; pointer-events: none;padding: 0 0 0 25px; left: 0; top: 10px;transition: 0.2s ease all;}
  .select-text:focus ~ .select-label, .select-text:valid ~ .select-label { color: #72c9b4; top: -20px; left: -25px; transition: 0.2s ease all;font-size: 14px;}
  .contact_frm { background: #d9fff6; padding: 80px 0;margin-top: 7%;}
  .frms_grds {display: grid;grid-template-columns: 1fr 1fr; grid-gap: 40px;}
  .inner_contact_frm { display: grid;grid-template-columns: 1fr 1fr;}
  .contact_frm_img {text-align: right;}
  .contact_frm_img img {margin-top: -28%;width: 80%;}

  .flag-input input
  {
    border: none !important;
    outline: 0;
    padding: 9px 0;
  }
  .flag-input input::placeholder
  {
    color: #72c9b4;
  }
  
option{ background: #fff !important; background-color: #fff !important;}
.calendly-inline-widget { 
  left: 50% !important; 
  margin-left: -100px!important; 
  position: fixed;
  z-index: 99999;

  }
#Contact
{
  display: block;
}
.testimonial_content{ position: relative;background: #f1f1f1 !important; padding: 30px;text-align: left;margin-bottom: 38px;height: 100%;}
.testimonial_content p{ font-size: 18px; color: #5f5f5f; line-height: 24px;}
.testimonial_profile h2{ font-size: 29px; color: #1a377a; line-height: 25px;font-weight: 600;}
.testimonial_profile h2 span{ font-size: 18px; color: #4b4b4b;display: block;margin-top: 8px; }
.testimonial_content h3{font-size: 25px; color: #fff; background: #1a377a;position: absolute;right: 0;bottom: -7px;padding: 9px 12px; border-radius: 0 0 2px 0;}
.testimonial_profile {border-top: 1px solid #ccc;margin-top: 30px;padding-top: 40px;display: flex;gap: 18px;align-items: center;}
.testimonial_content img { width: 100px !important;}
.testi_monial_sl #customer-testimonoals .item {text-align: left;}
.testi_monial
{
  width: 100%;
  overflow: hidden;
}
.testi_monial_sl { margin:0 -50px 0 -75px;}


.testi_monial_sl #customer-testimonoals {margin-top: 45px;}
.testi_monial_sl #customer-testimonoals .item {  text-align: center;padding: 0px;margin-bottom: 0px;opacity: 0.3; transform: scale3d(0.9, 0.9, 1); transition: all 0.3s ease-in-out; height: 100%;}
.testi_monial_sl #customer-testimonoals .owl-item.active.center .item { opacity: 1; transform: scale3d(1.0, 1.0, 1);}
.testi_monial_sl .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span { background: #010483; transform: translate3d(0px, -50%, 0px) scale(0.8);}
.testi_monial_sl .owl-carousel .owl-dots {display: inline-block; width: 100%; text-align: center; margin-top: 30px !important;}
.testi_monial_sl .owl-carousel .owl-dots .owl-dot{  display: inline-block;}
.testi_monial_sl .owl-carousel .owl-dots .owl-dot span{  background: #c1f3e8;display: inline-block; height: 20px; width: 20px; margin: 0 2px 5px; transform: translate3d(0px, -50%, 0px) scale(0.6);
transform-origin: 50% 50% 0;transition: all 250ms ease-out 0s;} 

#build-btn
{
  background: transparent linear-gradient(270deg,#4dc0de 0%,#1a377a 100%) 0% 0% no-repeat padding-box !important;
    border-radius: 50px;
    color: #fff;
    padding: 9px 22px;   
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
    position: relative;
  
}
.heading_group
{
  position: relative;
}
.heading_group h3
{
  font-size: 28px;
  color: #666;
  position: relative;
  line-height: 42px;
  margin-top: 24px;
}
.react-international-phone-input-container
{
  width: 100%;
}
.fixed-top
{
  z-index: 9999 !important;
}
.appcatogories
{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.appcat
{
  color: #3c3c3c;
  display: flex;
  align-items: center;
  /* background: #ebfffa; */
}
.appcat .spanwrap
{
  position: relative;
  margin-left: -42px;
  padding-top: 20px;
  display: block;
  width: calc(100% - 100px);
  height: 70px;
  top: -17px;
 
  font-size: 20px;
  text-align: center;
 
}
.appcat .spanwrap span {
  position: relative;
  border-radius: 5px;
  padding-top: 20px;
  display: block;
  width: 100%;
  height: 70px;
  top: -17px;
  z-index: -2;
  font-size: 20px;
  text-align: center;
  box-shadow: 10px 0px 5px 0px #ccc;
}
.appcaticon
{
  width: 138px;
  height: 145px;
  text-align: center;
 padding-top: 26px;
  position: relative;
}
.appcat .spanwrap::after {
  content: '';
  display: block;
  position: absolute;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  z-index: -4;
  background-color: #b6ebde;
  left: -20px;
  top: -6px;
  border-radius: 5px;
}
.appgreen .appcaticon
{
  background: url(../Assest/Images/polygreen.png) no-repeat;
  background-size: 100%;
}
.appred .appcaticon
{
  background: url(../Assest/Images/polyred.png) no-repeat;
  background-size: 100%;
}
.appbrown .appcaticon
{
  background: url(../Assest/Images/polybrown.png) no-repeat;
  background-size: 100%;
}

.appcaticon::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: -12px;
  left: 10px;
  z-index: -2;
  
}

.appgreen .appcaticon::after
{
  background: url(../Assest/Images/bgpoly-green.png) no-repeat;
  
}
.appred .appcaticon::after
{
  background: url(../Assest/Images/bgpoly-red.png) no-repeat;
}
.appbrown .appcaticon::after
{
  background: url(../Assest/Images/bgpoly-brown.png) no-repeat;
}
.appgreen span
{
  background: #ebfffa !important;
}
.appred span
{
  background: #ffe5e6 !important;
}
.appbrown span
{
  background: #ffe9d2 !important;
}


.appgreen .spanwrap::after
{
  background: #b6ebde !important;
}
.appred .spanwrap::after
{
  background: #f7c0c2 !important;
}
.appbrown .spanwrap::after
{
   background: #eac8a5 !important;
}
/* 
.appgreen .appcaticon::after
{
  border-top: 28.8675px solid #b6ebde;
}
.appred .appcaticon::after
{
  border-top: 28.8675px solid #ffe5e6;
}
.appbrown .appcaticon::after
{
  border-top: 28.8675px solid #ffe9d2;
}
.appcaticon::before
{
  content: "";
  position: absolute;
  top: -27px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  
  
}
.appcaticon::after
{
  content: "";
  position: absolute;
  bottom: -27px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  

} */
.updt {
  display: flex;
  align-items: center;
  gap: 25px;
}
.updt .lodimg img {
  max-width: 45px;
}
#hire-us
{
  background:#f8f8f8;
  padding:80px 0;
}
.hire-profile
{
  margin:2rem 4rem;
  background: #edeffb;
  padding: 60px 0;
}
.steps-para
{
  text-align: left;
}
.steps-para span
{
  color:#1a377a;
  font-size: larger;
  font-weight: 400;
}
#hire-us-profile
{
  margin-top: 30px;
}
#hire-us-profile .carousel-control-prev, #hire-us-profile .carousel-control-next
{
 
  display: none;
 
}
#hire-us-profile .carousel-indicators
{
  display: none;
}
.custom-carousel-indicators
{
  display: flex;
  align-items: center;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.custom-carousel-arrow
{
  cursor: pointer;
  font-size: 3rem;
  color: #060669;
}
.custom-carousel-arrow span
{
  display: flex;
  align-items: center;
}
.custom-carousel-arrow.disabled
{
  color: #666666;
}
.paging-n
{
  font-size: 1.5rem;
  color: #666666;
}
.current-index
{
  font-size: 2rem;
  color: #060669;
}
.profile-slide
{
  display: flex;
  background: #fff;
  padding: 40px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background:#fff url(/src/Assest/Images/bg-wrrow.png) no-repeat;
  background-size: 200px auto ;
  background-position: center right;
  height: 100%;
  align-items: center;
}
.profile-slide.redlt
{
  background:#fff url(/src/Assest/Images/red.png) no-repeat;
  background-size: 200px auto ;
  background-position: center right;
}
.profile-slide.pinklt
{
  background:#fff url(/src/Assest/Images/pink.png) no-repeat;
  background-size: 200px auto ;
  background-position: center right;
}
.profile-slide.orangelt
{
  background:#fff url(/src/Assest/Images/orange.png) no-repeat;
  background-size: 200px auto ;
  background-position: center right;
}
.profile-slide.purplelt
{
  background:#fff url(/src/Assest/Images/purple.png) no-repeat;
  background-size: 200px auto ;
  background-position: center right;
}

.profile-slide h3
{
  color:#6bc6b0;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 10px;
  margin-bottom: 1rem;
}
.profile-slide.redlt h3
{
  color:#f4d1ac ;
}
.profile-slide.pinklt h3
{
  color:#fbb2b5 ;
}
.profile-slide.orangelt h3
{
  color: #eac8a5;
}
.profile-slide.purplelt h3
{
  color:#c7b0f5;
}

.profile-slide h3 span
{
  display: block;
  color:#1a377a;
  font-size:large;
}
.pricing-sec
{
  padding: 80px 0;
  overflow: hidden;
}
.accordion-pricing
{
  margin-top: 70px;
  margin-bottom: 70px;
}
.accordion-pricing .accordion-button:not(.collapsed),.accordion-pricing  .accordion-button
{
  background-color: transparent !important;
  box-shadow: none !important;
  
}
.accordion-pricing .accordion-button
{
  color: #060669;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
}
.accordion-pricing .accordion-button img
{
  width:40px;
 margin: 0 !important;
}
.accordion-pricing .accordion-button::after
{
  display: none;
}
.accordion-pricing .accordion-item
{
  background:#f8f8f8;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 5px;
  margin-bottom: 10px;
}


/* 404 page  */





/* responsive */
@media (max-width: 1920px) {
    .banner_img img { width: 95%;margin-left: 5%; }
    .navbar-light .navbar-nav .nav-link
    {
      margin-right: 10px;
      font-size: 19px;
    }
    .app_process {
      min-width: 152px;
      min-height: 152px;
      max-width: 152px;
  }

    .app_process h3
    {
      color: #1a377a;
      font-size: 20px;
      font-weight: 300;
      }
      .heading {
        margin: 0 0 30px 0;
        font-size: 46.72px;
        font-weight: 500;
        line-height: 56px;
        color: #2e2c2c;
        position: relative;
        background: #fff;
        transition: transform .3s;
        z-index: 3;
        border-left: 6px solid #1a377a;
        padding-left: 18px;
        padding-top: 12px;
        padding-bottom: 20px;
        letter-spacing: -0.9px;
        background: linear-gradient(to left, rgba(255,255,255,0) calc(100% - 165px), #ebfffa 165px);
        text-transform: uppercase;
        }

    .heading span {
        color: #1a377a;
        font-size: 46px;
        font-weight: 500;
        line-height: 23px;
        margin-top: 11px;
        display: block;
    }
    .list-circle-arrow {
    
        font-size: 20px;
        font-weight: 300;
        text-indent: 10px;
        line-height: 42px;
        letter-spacing: -0.1px;
    }

    .banner_text h3 {
        color: #181818;
        margin-bottom: 0px;
        font-size: 32px;
        line-height: normal;
    }
    .solution-head {
        font-size: 34px;
        color: #1b2939;
        margin-top: 28px;
    }
    .book_your_call {
        width: auto;
        padding-top: 12px;
        padding-bottom: 15px;
        padding-left: 28px;
        padding-right: 28px;
        margin-top: 37px;
        color: #fff !important;
        background: #1a377a;
        font-size: 20px;
    }
    .banner_img img {
      
        margin-bottom: -50px;
    }
    .bar_heading {
        margin: 0;
        font-size: 36px;
        line-height: 46px;
    }
    .head_para {
        font-size: 22px;
    }
    .inner_portfolio_new h2 {
        font-size: 36px;
        color: #1a377a;
        font-weight: 600;
        letter-spacing: -1px;
        line-height: 42px;
    }
    .inner_portfolio_new h3 {
        font-size: 28px;
        color: #666;
        position: relative;
        line-height: 36px;
    }

    .right_text h3 {
        font-size: 36px;
        color: #2a4583;
        font-weight: 600 !important;
        margin: 0;
    }
    .right_text h5 {
        font-size: 20px;
        color: #3c3c3c;
        margin-bottom: 14px;
        margin-top: 5px;
        font-weight: 400;
    }
    .right_text h1 {
        font-size: 26px;
        color: #3c3c3c;
        position: relative;
    }
    .col_g h4 {
        font-size: 20px;
        color: #2a4583;
        font-weight: 600;
    }
    .col_g ul li {
        font-size: 16px;
        color: #464646;
        font-weight: 300;
        padding-bottom: 2px;
        position: relative;
    }
    .bar_heading_dn {
        text-align: center;
        font-size: 36px;
    }
    .head_para {
        font-size: 20px;
    }
    .serv h3 {
        color: #1a377a;
        font-size: 22px;
        line-height: 28px;
        font-weight: 400;
    }
    .serv p {
        color: #222222;
        font-size: 16px;
        line-height: 22px;
    }
    .faq .accordion-button {
        font-size: 18px;
    }
    .testimonial_profile h2 {
        font-size: 26px;
    }
    .testimonial_profile h2 span {
        font-size: 16px;
    }
    .react_service_grd h2 {
        font-size: 24px;
    }
    .react_service_grd p {
        font-size: 16px;
        
    }
    .faq .bar_heading span {
      font-size: 40px;
    }
    #CAPTCHA {
        width: 192px;
    font-size:36px;
    }


/* settings size decrease */
    .heading {
      margin: 0 0 30px 0;
      font-size: 38.72px;
      font-weight: 500;
      line-height: 42px;
    }
    .heading span {
      color: #1a377a;
      font-size: 38px;
    }
    .list-circle-arrow {
      font-size: 18px;
      font-weight: 300;
      text-indent: 10px;
      line-height: 36px;
    }
    .navbar-light .navbar-nav .nav-link {    
      font-size: 16px;
      line-height: 22px;
    }
    #build-btn {  
      padding: 8px 22px;
      font-size: 16px;
      line-height: 22px;
    }
    .banner_text h3 { 
      font-size: 28px;
    }
    .book_your_call {   
      padding-top: 10px;
      padding-bottom: 12px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 37px;  
      font-size: 18px;
    }
    .bar_heading_dn {
      text-align: center;
      font-size: 34px;
    }
    .heading_group h3 {
      font-size: 26px;
    }
    .head_para {
      font-size: 18px;
    }
    .inner_portfolio_new h2 {
      font-size: 32px;    
      line-height: 38px;
    }
    .inner_portfolio_new h3 {
      font-size: 26px;
      line-height: 34px;
    }
    .right_text h1 {
      font-size: 24px;
      color: #3c3c3c;
      position: relative;
    }
    .right_text h5 {
        font-size: 18px;
    }
    .right_text h3 {
        font-size: 34px;
    }
    .faq .bar_heading span {
      font-size: 38px;
    }
    .bar_heading {
        margin: 0;
        font-size: 34px;
        line-height: 38px;
    }
    /* settings size decrease */
}


@media (max-width:1400px)  {
  
  .app_process { 
    max-width: 130px;
    min-height: 130px;
    min-width: 130px;
  }

  .app_process h3 {
    color: #1a377a;
    font-size: 16px;
    line-height: normal;
    font-weight: 300;
}
}

@media (max-width:1200px) {
  .navbar-light .navbar-nav .nav-link {
    margin-right: 5px;
    font-size: 16px;
  }
  #build-btn {
    font-size: 16px;
    line-height: 20px;
  }
  .hire-profile
  {
    margin:2rem 0;
    background: #edeffb;
    padding: 60px 0;
  }
  .app_process::after
  {
  left: calc(100% + 11.5px);
  }
  .appDevProcess .innerapp_service
  {
    gap:50px;
  }
  .app_process {
      padding: 10px 0.3rem;
  max-width: 110px;
      min-height: 110px;
      min-width: 110px;
  }
  .app_process h3 {
    color: #1a377a;
    font-size: 14px;
    line-height: normal;
    font-weight: 300;
}
}
@media (max-width:1049px)  {
  .appDevProcess .innerapp_service {
    display: grid;
    grid-template-columns: 168px 168px 168px;
    gap:58px;
    position: relative;
    padding-top: 55px;
    
    justify-content: center;
  }
  .app_process {
    max-width: 168px;
    min-height: 168px;
    min-width: 168px;
  }
  .app_process:nth-child(3)::after {
    display: none;
  }
  .app_process img {
    margin-bottom: 10px;
    max-width: 100%;
  }
  .app_process h3 {
    color: #1a377a;
    font-size: 16px;
    line-height: normal;
    font-weight: 300;
}
}
@media (max-width:991px) {
  .profile-slide
  {
    flex-flow: column !important;
  }
  .appcatogories
{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  
}
  .banner-sub {
    margin-top: 82px;
}
  .captcha-d
  {
    display: grid;
    grid-template-columns: 1fr;
  }
  #CAPTCHA {
    width: 190px;
    height: auto;
    background: linear-gradient(to left, #ececec, silver, #ececec);
    color: #089;
    font-size: 24px;
    line-height: 40px;
    border: 2px solid #888;
    border-radius: 2px;
  }
}
@media (max-width:767px) {
  .appcatogories
  {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
  .px-sma-0
  {
    padding-left:0 !important ;
    padding-right: 0 !important;
  }
}
@media (max-width:650px)  {
  .appDevProcess .innerapp_service {
    display: grid;
    grid-template-columns: 168px 168px;
    gap:58px;
    position: relative;
    padding-top: 55px;
    
    justify-content: center;
  }
  .app_process {
    max-width: 168px;
    min-height: 168px;
    min-width: 168px;
  }
  .app_process:nth-child(2)::after,.app_process:nth-child(4)::after {
    display: none;
  }
  .app_process:nth-child(3)::after {
    display: block;
  }
}

@media (max-width: 1200px) {
.inner_react_service {grid-template-columns: 1fr 1fr;}
.inner_port_text { grid-template-columns: auto;}
.inner_banner {grid-template-columns: 45% auto;}
.heading span {font-size: 49px;line-height: 55px; margin-top: 4px;}
.heading { margin: 0 0 24px 0;font-size: 40px;line-height: 54px;}
.banner_text h3 {margin-bottom: 15px;font-size: 30px; line-height: 36px;}
.banner_text p { font-size: 22px;}
.inner_contact_frm { grid-template-columns: auto;}
.contact_frm_img img {margin-top: 0;width: 300px;}
.contact_frm_img { position: absolute;right: 0;top: -263px;}
.contact_frm { padding: 150px 0 60px 0;margin-top: 100px;}
}

@media (max-width: 1024px) {
.inner_banner {grid-template-columns: auto;}
.banner_img img { width: 60% !important; margin-left: 20% !important;}
}
@media (max-width: 991px) {
.service_grd {grid-template-columns: 1fr 1fr;}
.serv {border: 1px solid #c9cbcb !important;}
.service_grd {border: 1px solid #c9cbcb !important;}
.inner_faq { grid-template-columns: auto; grid-gap: 55px;}
.foot_menu {padding: 40px 0;}
.dated
{
  right: -20px;
}
}

@media (max-width: 600px) {
.testi_monial_sl { margin:0px;}
.testimonial_content {margin: 20px !important;}
.inner_portfolio_new h2 {font-size: 36px; line-height: 40px;}
.inner_portfolio_new {
  position: relative;
  padding-bottom: 10px;
}
.main_grds {grid-template-columns: auto;}
.dated {
  font-size: 16px;
  width: 70px;
  padding: 8px 0;
  right: -20px;
}
.inner_port_text .left_text {
  padding: 25px 10px 0 10px;

}
.dated span {font-size: 14px;}
.right_text h3 {font-size: 35px;}
.right_text h5 {font-size: 22px; margin-bottom: 16px;font-weight: 400;}
.right_text h1 {font-size: 22px; padding-right: 40px;}
.right_text h1::after { width: 35px; margin-left: 10px; right: 0;}
.right_text {padding-top: 48px;}
.service_grd {grid-template-columns: auto;}
.serv {border-bottom: 1px solid #c9cbcb !important; border-width: 0 0 1px 0 !important;}
.service_grd {border: 0px solid #c9cbcb !important;}
.head_para { font-size: 21px; line-height: 31px;}
.bar_heading_dn {font-size: 35px;}
.inner_react_service {grid-template-columns: auto !important;}
.inner_portfolio_new h2 {
  font-size: 30px;
  line-height: 40px;
}
.right_text h3 {
  font-size: 20px;
}
.right_text h5 {
  font-size: 17px;
  margin-bottom: 16px;
  font-weight: 400;
}
.right_text h1 {
  font-size: 18px;
  padding-right: 40px;
}  
.showMore {
  font-size: 12px;
}
.slick-dots li {
  width: 10px;
  height: 10px;
}
}
@media (max-width: 500px) {

  .banner_img img { width: 90% !important; margin-left: 5% !important;}
}
/* 
  .logo_grd {display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;gap: 10px;}
  .logo_grd img {max-width: 100%;height: auto;}
  .inner_Services h3 { color: #1acc8d; font-size: 20px; font-weight: 500; margin-top: 48px;}
  .portfolio{padding:90px 0 0 0;}
  .detail_grd h4 {color: #010483;font-size: 20px;font-weight: 700;}
  .detail_grd ul { list-style: none; padding: 0 ;}
  .detail_grd ul li {padding-bottom: 5px;padding-left: 15px;position: relative; background: url(../Assest/Images/right.png);background-size: 8px;background-position: left 7px;
   background-repeat: no-repeat;list-style: none; }
   .inner_detail_grd {display: grid;grid-gap: 20px;justify-content: space-between;padding: 17px 0;grid-template-columns: auto auto auto;}
   .portfolio_det {display: grid;grid-template-columns: 330px auto;padding: 58px 0 0 0; grid-gap: 40px;}
   .portfolio_det.revert {display: grid;grid-template-columns: auto 330px;padding: 58px 0 0 0; grid-gap: 40px;}
   .slider_overlay {position: absolute;z-index: 55;width: 100%;}
   .phone_slider .carousel-item img {padding: 12px 4px;}
   .phone_slider {position: relative;max-width: 320px;}
   .phone_slider .carousel-indicators { bottom: 69px; z-index: 55;}
   .phone_slider .carousel-indicators [data-bs-target] {width: 8px; height: 8px;border-radius: 50%;background: #fff;padding: 0px !important; border: 2px solid #010483c4;}
   .playstore img {width: 45px; box-shadow: 0 2px 3px 0px #808080;border-radius: 50%;}
   .playstore_inner {display: flex;gap: 10px;flex-wrap: wrap;}
   .playstore {margin-top: 40px;}
   .portfolio_content h1 {font-weight: 600;font-size: 30px;color: #010483;}
   .portfolio_content h3 {color: #1acc8d;font-size: 20px;font-weight: 700; text-transform: uppercase;padding: 12px 0 0 0;}
   .phone_slider .carousel-control-next, .phone_slider .carousel-control-prev { display: none !important;}
   .offcanvas {background: #00000082 !important;}
  .offcanvas #offcanvasNavbarLabel-expand-false {color: #fff; font-weight: 600; letter-spacing: 2px; border-bottom: 2px solid; padding: 0 0 3px 0;}
  .offcanvas-body hr {color: #fff;border: 1px solid;}
  .offcanvas-header .btn-close{ background: url(../Assest/Images/cross.png);opacity: 1;background-size: 20px; background-repeat: no-repeat; background-position: center;}
  .offcanvas-body .nav-link {color: #fff !important;font-size: 25px;line-height: 36px;padding-bottom: 16px;  }
  .offcanvas-body .nav-link:hover {color: #fff !important; opacity:.8  }
  .offcanvas-body {display: flex;align-items: baseline; padding-top: 49px !important;}
  .testimonials{ background: url(../Assest/Images/testimonial.jpg); background-attachment: fixed;}
  .testimonials {padding: 110px 0;}
  .testimonials .captn img {width: 150px !important;border-radius: 50%;height: 150px;margin: 8px auto 60px auto; border: 6px solid #010483; box-shadow: 0px 0 0px 5px #fff;}
  .testimonials .captn {text-align: center;padding: 36px 10%;}
  .testimonials .captn h1 {color: #fff;font-size: 25px;text-transform: uppercase;}
  .testimonials .captn h3 {color: #fff;font-size: 20px;text-transform: capitalize;}
  .testimonials .captn p {color: #fff;}
  .Contact {padding: 90px 0px 40px 0;}
  .inner_contac {display: grid;grid-template-columns:1fr 360px;grid-gap: 40px;}
  .contact_logo {display: grid;grid-template-columns: 1fr 1fr 1fr 1fr;grid-gap: 34px;max-width: 600px;padding-top: 65px;}
  .contact_logo img {width: 80%;border-radius: 20px; margin: auto;}
  .contact_form_section p.headings {color: #716f6f;font-weight: 400;font-size: 20px;padding-top: 10px;}
  .contact_form {box-shadow: 0 5px 4px 4px #000;padding: 10px 24px 24px 24px;border-radius: 22px;}



  .contact_form .form-container {width: 100%;padding: 10px;margin: auto; }
  .contact_form .form-row {position: relative;margin-top: 25px;}
  .contact_form .form-label {position: absolute;top: 11px;left: 20px;color: #999;cursor: text;transition: all .15s ease-in-out 0s;}
  .contact_form .form-textbox,   .contact_form .form-submit {width: 100%;padding: 9px 20px;height: 47px !important;}
  .contact_form .form-textbox2 {width: 100%;padding: 9px 20px;height: 100px !important;}
  .contact_form .form-textbox, .contact_form .form-textbox2 {	box-shadow: inset 0 0 10px rgba(0,0,0,.1);}
  .contact_form .form-textbox:focus ~ .form-label, .contact_form .form-textbox2:focus ~ .form-label,   .contact_form .form-textbox:valid ~ .form-label {top: -1.5em;left: 0;font-size: .8em;color: inherit;cursor: pointer;}
  .contact_form .form-submit {background-color: #010483;font-weight: 700;color: #fff;transition: opacity .15s ease-in-out 0s;border: none;}
  .contact_form .form-submit:hover {opacity: .75;}
  .contact_form .form-textbox:focus-visible, .contact_form .form-textbox2:focus-visible {box-shadow: none !important;border: none;outline: 1px solid #333;}
  .foot_menu h3 { margin-bottom: 48px;}
  .foot_menu {margin-bottom: 25px;}
  .career p {line-height: 31px;color: #fff; font-size: 16px;}
  .career {margin: 18px 0;}
  .career a { color: #fff;}
  .copyrt p {text-align: center; padding-top: 42px;}
  .footer.py-5 {padding-bottom: 10px !important;}
 
  .and_ios span { display: flex; margin-top: 10px;}
  .and_ios img { width: 50px; margin: 0 5px;}

  .inner_react_service {display: grid;grid-template-columns: 1fr 1fr 1fr;grid-gap: 40px;position: relative;padding-top: 55px;}

  .react_service_grd {position: relative;background: #fff;transition: transform .3s;z-index: 3;}
  .react_service_grd:hover { transform: scale(1.01);background: #fff !important;}
  .grd_txt {padding: 21px 0px 10px 0px;background: #fff;}
  .react_service {padding: 85px 0;}
  .react_service_grd h1 {
    margin: 0; font-size: 25px; font-weight: 700; text-transform: uppercase;font-family: "Poppins", sans-serif;color: #010483; padding-bottom: 0px; padding-top: 0;
    position: relative;padding-left: 36px; margin-bottom: 17px; min-height: 40px; }
  .react_service_grd h1::after { position: absolute; height: 100%;width: 5px;background: red; content: "";left: 0px;top: 0;}
  .testimonials .captn p{ display: flex; padding:0 50px; position: relative; line-height: 32px;}
  .testimonials .captn p .quote_start {width: 18px !important;border: none !important;margin: -20px 8px 0 0 !important;box-shadow: none !important;
    border-radius: 0px !important;height: 18px !important;}
    .testimonials .captn p .quote_end{margin:-13px 0px 0px 8px !important;height: 18px !important;}
    .down_arr {
      position: absolute;
      right: 0;
      left: 0;
      margin: auto;
      bottom: 100px;
      z-index: 555;width: 100px;
      text-align: center;
    }
    .down_arr img {
      width: 65%;
    }
    .down_arr h6 {
      color: #fff;
      font-size: 13px;
      text-align: center;
    }
    .down_arr a {color:#fff; text-decoration: none;} */
/* responsive */



.owl-carousel .owl-stage{display: flex !important;}


.d-v-grid
{
  display: flex !important;
  gap: 5px !important;
  margin-top: 0 !important;
  flex-flow: column;
  align-items: center !important;
}
.d-v-grid span:first-child
{
  margin-top: 0;
  margin-bottom: 5px;
}
.and_ios_icon
{
  display: flex;
  gap: 20px;
}

@media (max-width: 1700px) {
  .banner_img img { width: 95%;margin-left: 5%; }
  }

@media (max-width: 1200px) {
.inner_react_service {grid-template-columns: 1fr 1fr;}
}

@media (max-width:425px)  {
  .appDevProcess .innerapp_service {
    display: grid;
    grid-template-columns: 168px;
    gap:58px;
    position: relative;
    padding-top: 55px;
    
    justify-content: center;
  }
  .app_process {
    max-width: 168px;
    min-height: 168px;
    min-width: 168px;
  }
  .app_process:nth-child(2)::after,.app_process:nth-child(4)::after {
    display: block;
  }
  .app_process::after {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    top: calc(100% + 22.5px);
    left: calc(50% - 11.5px);
  }
  h1.bar_heading {
    margin: 0;
    font-size: 36px;
    line-height: 40px;
  }
  .heading {
    margin: 0 0 24px 0;
    font-size: 28px;
    line-height: 36px;
  }
  .heading span {
    font-size: 36px;
    line-height: 40px;
  }
}   





















/* cookee css  */
.CookieConsent {
    bottom: 90px !important;
    height: 160px;
    max-width: 415px;
    right: 16px !important;
    left: inherit !important;
    display: block !important;
    border-radius: 5px;
    background: transparent linear-gradient(270deg, #4e93a5 0%, #163272 100%) 0% 0% no-repeat padding-box !important;
  }
  .CookeDiv button{
    margin: 0 auto !important;
    display: flex;
    justify-content: center;
    background: rgb(255 255 255) !important;
  }
  
  @media only screen and (max-width: 440px){
  
    .CookieConsent {
        height: 155px;
        max-width: 375px;
    }
  
  }
  @media only screen and (max-width: 440px){
  
    .CookieConsent {
        max-width: 350px;
    }
    .cookieText{
        font-size: 14px;
    }
  
  }
  



.Page404{
    background: #eaedff !important;
    padding: 150px 0px  140px  0px;
}
.PageErrorData{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.errorText {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}
.errorpic img {
    width: 100%;
    max-width: 480px;
}
.errorText img {
    width: 100%;
    max-width: 480px;
}
.errorText h4{
    color: #2a2a2a;
    margin: 0;
    font-size: 35px;
    font-weight: 300;
}
.goerror{
    display: flex;
    align-items: center;
    gap: 10px;
}
.goerror h6{
    margin: 0;
    color: #706d79;
    font-size: 22px;
    font-weight: 400;
}
.erlink{
    display: flex;
    align-items: center;
    gap: 1px;
}
.erlink a {
    color: #162d61;
    font-size: 20px;
}

/* Responsive  */

@media only screen and (max-width: 1200px){

    .errorpic img {
        max-width: 365px;
    }
    .errorText img {
        max-width: 400px;
    }
    .errorText h4 {
        font-size: 28px;
    }
    .goerror h6 {
        font-size: 20px;
    }
    .erlink a {
        font-size: 18px;
    }
    .errorText {
        gap: 25px;
    }

}
@media only screen and (max-width: 991px){

    .PageErrorData {
        grid-template-columns: 1fr;
       gap: 70px;
    }
    .errorpic {
        text-align: center;
    }
    .errorText img {
        max-width: 425px;
    }
    .errorText h4 {
        font-size: 30px;
    }
    .goerror h6 {
        font-size: 22px;
    }
    .erlink a {
        font-size: 20px;
    }

}

@media only screen and (max-width: 520px){

    .errorpic img {
        max-width: 300px;
    }
    .errorText img {
        max-width: 375px;
    }
    .errorText h4 {
        font-size: 24px;
    }
    .goerror h6 {
        font-size: 20px;
    }
    .erlink a {
        font-size: 18px;
    }
    .errorText {
        gap: 18px;
    }
    .Page404 {
        padding: 150px 0px 100px 0px;
    }


}

@media only screen and (max-width: 410px){

    .errorpic img {
        max-width: 265px;
    }
    .errorText img {
        max-width: 320px;
    }
    .errorText h4 {
        font-size: 21px;
    }
    .goerror h6 {
        font-size: 18px;
    }


}